import React from 'react';
import './TabSection2.css'
import l11 from '../../images/fivec/1-1.jpg';
import l12 from '../../images/fivec/1-2.jpg';
import l21 from '../../images/fivec/2-1.jpg';
import l22 from '../../images/fivec/2-2.jpg';
import Carousel from 'react-bootstrap/Carousel';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';


function TabSection2() {
    return (
        <div className='tab2-container'>
            <h1 className='blue-text centerd-text '>課程大綱</h1>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className=' tab-row'>
                    <Row class='flex-column'>
                        <Nav.Link eventKey="first">Level 1 <br />外送機器人</Nav.Link>
                        <Nav.Link eventKey="second">Level 2 <br />防疫機器人</Nav.Link>
                    </Row>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        <Nav variant="pills" className="flex-row">
                            <Nav.Item>
                            </Nav.Item>
                            <Nav.Item>
                            </Nav.Item>
                            <Nav.Item>
                            </Nav.Item>
                            <Nav.Item>
                            </Nav.Item>
                        </Nav>
                    </Col>

                    <Col xs={12} sm={12} md={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <Carousel
                                    interval={100000}
                                >
                                    <Carousel.Item>
                                        <img src={l11} alt="5C_邏輯機器人_level1-1" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={l12} alt="5C_邏輯機器人_level1-2" />
                                    </Carousel.Item>
                                </Carousel>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <Carousel
                                    interval={100000}
                                >
                                    <Carousel.Item>
                                        <img src={l21} alt="5C_邏輯機器人_level2-1" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={l22} alt="5C_邏輯機器人_level2-2" />
                                    </Carousel.Item>
                                </Carousel>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div >

    );
}

export default TabSection2;


