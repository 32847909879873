import React from "react";
import sp2014 from "../../../images/home/sp2-14.png";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ES() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="singel-student-project2">
                <div className="student-project2-frame">
                    <a
                        herf=""
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickOpen}
                    >
                        <img src={sp2014} alt="earth shatter" />
                    </a>
                </div>
                <p className="sp2-title">Earth Shatter</p>
                <p className="sp2-name">Juji Wuji</p>
                <p className="sp2-played">2453 PLAYS </p>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="game-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="game-wrapper">
                        <div className="iframe-wrapper-small">
                            <iframe
                                src="https://pixelpad.io/app/oorvvtraghm/"
                                scrolling="no"
                                title="demo-game"
                            />
                        </div>
                        <p className="game-title">Earth Shatter</p>
                        <p className="game-name">
                            Juji Wuji
                            <span className="game-played">｜2453 PLAYS </span>
                        </p>
                        <p className="game-des">
                            Hello! Welcome to Earth Shatter! This is my first
                            game, so it might not be the best one that you will
                            have played. But, nonetheless, here is Earth
                            Shatter!
                        </p>
                        <p className="game-des">
                            This game represents the theme because the Earth is
                            in danger from aliens. "Save the World" being the
                            theme, You will have to stop aliens coming to Earth
                            and destroying it.
                        </p>
                        <p className="game-des">
                            To play the game is easy: All you have to do is use
                            the mouse to stop incoming asteroids, bombs, ball
                            spikes, and missiles. Make sure to hold left click
                            though, because if you don't you will be letting
                            those pesky items hit the Earth. There are ways to
                            counter this though. <br/>In the game, you will encounter
                            blue orbs and yellow gems. These are your friends
                            though. so let them hit the Earth. Once your Health
                            becomes 0 that's it. The Earth will shatter. So try
                            to save the world You will have 3 minutes to
                            survive. Once those 3 minutes are up, the aliens
                            will deem you worthy and will leave Earth
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default ES;
