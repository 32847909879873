import React from "react";
import sp2016 from "../../../images/home/sp2-16.png";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function BJ() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="singel-student-project2">
                <div className="student-project2-frame">
                    <a
                        herf=""
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickOpen}
                    >
                        <img src={sp2016} alt="banana jump" />
                    </a>
                </div>
                <p className="sp2-title">Banana Jump</p>
                <p className="sp2-name">Mike Daniels</p>
                <p className="sp2-played">6388 PLAYS </p>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="game-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="game-wrapper">
                        <div className="iframe-wrapper">
                            <iframe
                                src="https://pixelpad.io/app/ufeafzjmtyz/"
                                scrolling="no"
                                title="demo-game"
                            />
                        </div>
                        <p className="game-title">Banana Jump</p>
                        <p className="game-name">
                            Mike Daniels
                            <span className="game-played">｜6388 PLAYS </span>
                        </p>
                        <p className="game-des">
                            Save the world by collecting coins so you can get to
                            the golden banana and save the world from
                            starvation. Jump from platform to platform, making
                            sure to avoid the spike balls, and spiders.
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default BJ;
