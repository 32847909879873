import React, {useEffect} from 'react';
import '../../App.css';
import './PythonGame.css';
import CourseTab from '../functions/TabSection';
import game from '../../images/physical/pythongame.jpg';
import game2 from '../../images/physical/pythongame-2.png';
import game3 from '../../images/physical/pythongame-3.jpg';
import game4 from '../../images/physical/pythongame-4.jpg';
import Carousel from 'react-bootstrap/Carousel';
import { Helmet } from 'react-helmet'

const TITLE = 'Python 程式設計｜沙箱科技教育學院';

function PythonGame() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>  
            <div className='python-game-container'>
                <Helmet>
                    <title>{ TITLE }</title>
                </Helmet>
                <div className='python-game-photo-container'>
                    <Carousel
                        interval={5000}
                    >
                        <Carousel.Item>
                            <img src={game} alt='python_game_students' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={game2} alt='python_game_student' />
                        </Carousel.Item>
                         <Carousel.Item>
                            <img src={game3} alt='python_game_student' />
                        </Carousel.Item>
                         <Carousel.Item>
                            <img src={game4} alt='python_game_student' />
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className='pythongame-container'>
                    <h1 className='blue-text centerd-text'>
                        學習國際主流程式語言
                    </h1>
                    <p className='more-line-height'>
                        程式語言已成為國際頂尖人才的基礎技能，數位時代下擁有更廣泛的國際工作機會，這一代從小學習程式語言的孩子將能掌握先機在未來將人工智慧和軟體延伸應用到各行各業。
                    </p>
                    <p className='more-line-height'>
                        沙箱Sandbox從北美開始到台灣各地課後進修班及實驗小學開設Python程式設計課程，深受家長及校方肯定。我們了解孩子的學習需求，專為國小學生打造遊戲化的課程內容及培訓專業師資。孩子使用的是拿大公立學校採用的專業學習平台，訓練孩子邏輯思考與解決問題的能力並實際產出成果的同時與國外的學生互相切磋學習。
                    </p>
                </div>
                <CourseTab />
            </div>
            
        </>
    )
}

export default PythonGame;
