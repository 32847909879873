import React, { useState } from "react";
import { Form, Container } from "semantic-ui-react";
import "../../App.css";
import "./HeroSection.css";
import home from "../../images/home.gif";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import "reactjs-popup/dist/index.css";
import "semantic-ui-css/semantic.min.css";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

function HeroSection() {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [formData, setFormData] = useState({});
    const [message, setMessage] = useState("");

    const handleInput = (e) => {
        const copyFormData = { ...formData };
        copyFormData[e.target.name] = e.target.value;
        setFormData(copyFormData);
    };

    const sendData = async (e) => {
        e.preventDefault();
        const { name, phone, email } = formData;
        try {
            const response = await fetch(
                "https://v1.nocodeapi.com/sandboxacadamytw/google_sheets/ZxKNLzNUQNniqSSz?tabId=Trial",
                {
                    method: "post",
                    body: JSON.stringify([
                        [new Date().toLocaleString(), name, phone, email],
                    ]),
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            const json = await response.json();
            console.log("Success:", JSON.stringify(json));
            setMessage("您的表單已送出！將由客服人員聯絡您。");
        } catch (error) {
            console.error("Error:", error);
            setMessage("表單送出失敗，請聯絡客服。");
        }
    };

    return (
        <>
            <div className="hero-container">
                <img
                    src={home}
                    alt="sandboxacademy_video"
                   
                img/>
                <div className="video-words white-text">
                    <h1>孩子的程式教育起點！</h1>

                    <Button
                        variant="outlined"
                        className="white-text hero-trial-button"
                        onClick={handleClickOpen}
                    >
                        專人服務
                    </Button>
                    <Dialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                    >
                        <DialogTitle
                            id="customized-dialog-title"
                            onClose={handleClose}
                        >
                            沙箱科技學院｜聯絡方式
                        </DialogTitle>
                        <DialogContent dividers>
                            <Typography gutterBottom>
                                家長您好！沙箱所開設的兒童Python課程，針對10-15歲的孩子打造免出門就可學習的線上小班教學，
                                為您對孩子提供最安全、有趣味的程式教學！ <br />
                                如果您想了解更多，請留下聯絡資訊，我們將由專人盡快聯繫您。
                            </Typography>
                            <Container fluid className="container">
                                <Form
                                    className="form input-form"
                                    id="contact"
                                    name="contact"
                                    required
                                    onSubmit={sendData}
                                >
                                    <Form.Field>
                                        <label>家長姓名</label>
                                        <input
                                            name="name"
                                            type="text"
                                            placeholder="任何可以稱呼您的方式"
                                            required
                                            onChange={handleInput}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>連絡電話</label>
                                        <input
                                            name="phone"
                                            type="text"
                                            placeholder="聯絡電話"
                                            required
                                            onChange={handleInput}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <label>電子信箱</label>
                                        <input
                                            name="email"
                                            type="email"
                                            placeholder="常用電子信箱"
                                            required
                                            onChange={handleInput}
                                        />
                                    </Form.Field>
                                    <Button
                                        color="blue"
                                        type="submit"
                                        value="Send"
                                        className="centered"
                                    >
                                        Submit
                                    </Button>
                                    <div className="form-message">
                                        {message}
                                    </div>
                                </Form>
                            </Container>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </>
    );
}

export default HeroSection;
