import React from "react";
import sp206 from "../../../images/home/sp2-6.png";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TFS() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="singel-student-project2">
                <div className="student-project2-frame">
                    <a
                        herf=""
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickOpen}
                    >
                        <img src={sp206} alt="through four seasons" />
                    </a>
                </div>
                <p className="sp2-title">Through Four Seasons</p>
                <p className="sp2-name">Grace Wu</p>
                <p className="sp2-played">1658 PLAYS </p>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="game-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="game-wrapper">
                        <div className="iframe-wrapper">
                            <iframe
                                src="https://pixelpad.io/app/sclysthyjsn/"
                                scrolling="no"
                                title="demo-game"
                            />
                        </div>
                        <p className="game-title">Through Four Seasons</p>
                        <p className="game-name">
                            Grace Wu
                            <span className="game-played">｜1658 PLAYS </span>
                        </p>

                        <p className="game-des">
                            This game is about a warrior prepares in spring.
                            summer and fall to fight against a demon in winter.
                            The gameplay of this game is mainly parkour, and the
                            main character will learn how to use magic to beat
                            the demon. What's more, we made a small audio game
                            to increase the difficulty of the game. Try to solve
                            the puzzel and enjoy the music! We are looking
                            forward to seeing you to save the world!
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default TFS;
