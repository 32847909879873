import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import '../../App.css';
import './Error404.css';
import { Helmet } from 'react-helmet'

const TITLE = '頁面不存在｜沙箱科技教育學院';

function Error404() {

    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className='error404-container'>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
                <h1 className='centerd-text'>您所查詢的頁面不存在</h1>
                <div className='more-btn'>
                    <Link to='/'>
                        <Button variant="outlined" >
                            點我回首頁
                        </Button>
                    </Link>
                </div>
            </div>

        </>
    )
}

export default Error404;
