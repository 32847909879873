import React from "react";
import "../../App.css";
import "./Student2Section.css";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import NT from "./studentprojets/NT";
import BJ from "./studentprojets/BJ";
import DS from "./studentprojets/DS";
import C from "./studentprojets/C";
import T from "./studentprojets/T";
import ES from "./studentprojets/ES";
import EF from "./studentprojets/EF";
import STP from "./studentprojets/STP";
import TFS from "./studentprojets/TFS";
import BB from "./studentprojets/BB";
import AF from "./studentprojets/AF";
import TE from "./studentprojets/TE";
import ZS from "./studentprojets/ZS";
import S from "./studentprojets/S";
import FF from "./studentprojets/FF";
import HF from "./studentprojets/HF";
import SW from "./studentprojets/SW";
import BN from "./studentprojets/BN";

function Student2Section() {
    return (
        <>
            <div className="student-project2-container">
                <h1 className='yellow-text'>學生作品區</h1>
                <p className='click'>* 點擊圖片即可開啟遊戲</p>
                <Grid container spacing={2} className="sp2-grid">
                    <Grid item xs={6} sm={3} md={3}>
                        <NT />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <BJ />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <DS />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <C />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <T />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <ES />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <EF />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <STP />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <TFS />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <BB />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <AF />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <TE />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <ZS />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <S />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <FF />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <HF />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <SW />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <BN />
                    </Grid>
                </Grid>
                <div className="result-btn">
                    <a
                        href="https://pixelpad.io/newsfeed/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button variant="contained">作品平台</Button>
                    </a>
                </div>
                <p className="centerd-text note">
                    （ 遊戲即時下載次數請依照作品平台為主 ）
                </p>
            </div>
        </>
    );
}

export default Student2Section;
