import React , { useEffect } from 'react';
import '../../App.css';
import './Policy.css';
import { Helmet } from 'react-helmet'

const TITLE = '用戶隱私政策｜沙箱科技教育學院';

function Policy() {

    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className='policy-container'>
                <Helmet>
                    <title>{ TITLE }</title>
                </Helmet>
                <h1>愛樂教育顧問有限公司用戶隱私政策</h1>
                <div className='policy-section'>
                    <p>更新日期：2021年5月24日</p>
                    <p>生效日期：2021年5月24日</p>
                </div>
                <div className='policy-section'>
                    <p className="margin-left" >
                        沙箱程式設計學院（即愛樂教育顧問有限公司，以下也稱“我們”）尊重並保護所有使用沙箱程式教育學院平台（包括網站www.pixelpad.io或其子域名網站，沙箱線上學習平台以下也稱“平台”）服務的用戶隱私信息，<strong>並對收集的兒童（指不滿十四週歲的未成年人，下同）個人信息將採取更高級別的保護規則與措施</strong>。鑒於此，沙箱程式教育學院制定本《沙箱程式教育學院用戶隱私政策》（以下或稱“本政策”），本政策為《沙箱程式教育學院用戶服務協議》的重要構成部分，除另有特別約定外，本政策用語適用與《沙箱程式教育學院用戶服務協議》同一用語相同的定義。
                    </p>
                    <p className="margin-left" >
                        在使用沙箱程式教育學院平台服務前，請您務必仔細閲讀並透徹理解本政策，特別是以粗體或粗體加下劃線標識的條款，應重點閲讀，在確認充分理解並同意後再開始使用。一旦您開始使用沙箱程式教育學院平台服務，即表示您已充分理解並同意沙箱程式教育學院按照本政策收集、存儲、使用、轉移、披露及處理您的個人信息。
                    </p>
                    <p className='margin-left bold-underline'>
                        若您是沙箱程式教育學院平台兒童用戶的監護人，請您務必仔細閲讀本政策（特別是其中專門適用於兒童個人信息的內容，該等內容已粗體加下劃線的方式進行標註）併為您的被監護人（以下稱“兒童”）做出其是否適合使用沙箱程式教育學院服務的判斷。我們要求兒童在您的監護下共同閲讀並接受本政策，並應在您的同意及指導下使用沙箱程式教育學院平台服務。若您或兒童選擇“同意”或兒童使用/繼續使用沙箱程式教育學院平台服務的，即表示您同意沙箱程式教育學院按照本政策收集、存儲、使用、轉移、披露及處理兒童的個人信息；若您不接受本政策的條款內容，您或兒童也可選擇“拒絶”，但該選擇可能導致沙箱程式教育學院平台的服務和功能無法正常運行或使用受到影響，您應要求兒童立即停止使用沙箱程式教育學院平台服務。如您希望查看兒童個人信息或對兒童個人信息做進一步處理的，可按照本政策規定向我們反饋。
                    </p>
                </div>
                <div className='policy-section'>
                    <h5>《沙箱程式教育學院用戶隱私政策》導讀目錄</h5>
                    <p>一、關於沙箱程式教育學院如何收集個人信息</p>
                    <p>二、沙箱程式教育學院如何使用Cookie和同類技術</p>
                    <p>三、沙箱程式教育學院如何存儲和保護個人信息</p>
                    <p>四、沙箱程式教育學院如何使用個人信息</p>
                    <p>五、沙箱程式教育學院如何對外提供個人信息</p>
                    <p>六、您的權利</p>
                    <p>七、我們如何處理未成年人的個人信息</p>
                    <p>八、本政策如何更新</p>
                    <p>九、對第三方責任的聲明</p>
                </div>
                 <div className='policy-section'>
                    <h5>一、關於沙箱程式教育學院如何收集個人信息</h5>
                    <p className="margin-left" >
                        為了保證用戶註冊、課程服務、在線課程、客戶服務各項基本業務功能及個性化營銷、
                        提供個性化用戶體驗的各項擴展業務功能的正常運行，我們會收集、使用下列與您有關的信息。
                        如果您不提供相關信息，可能無法註冊成為我們的用戶或無法使用和享受我們提供的某些產品和服務，
                        或者無法達到相關產品和/或服務擬達到的效果。沙箱程式教育學院會出於本政策所述的以下目的，
                        收集沙箱程式教育學院平台用戶（以下也稱“您”）的個人信息：
                    </p>
                    <h6>1. 註冊成為用戶：</h6>
                    <p className="margin-left" >
                        <strong>
                            要使用沙箱程式教育學院產品和/或服務，建議您成為沙箱程式教育學院正式會員。
                            當您註冊沙箱程式教育學院賬號或使用沙箱程式教育學院提供的產品和/或服務時，
                            您需要向我們提供您的手機號碼、電子郵箱、學員年齡信息（如適用）。
                        </strong>
                    </p>
                    <p className="margin-left" >
                        您提供的上述信息，將在您使用服務期間持續授權沙箱程式教育學院使用。
                        <strong>
                            在您註銷賬號時，沙箱程式教育學院平台將停止使用上述信息，或進行隔離和匿名化處理後按照法律規定予以留存。
                        </strong>
                    </p>
                    <h6>2. 使用沙箱程式教育學院平台各模組功能：</h6>
                    <p className="margin-left" >
                        您在使用沙箱程式教育學院平台的各項功能服務過程中，根據您與沙箱程式教育學院平台的互動和您所作出的選擇，
                        沙箱程式教育學院會收集您的相關個人信息，例如：
                    </p>
                    <p className="li-content" >
                        （1）您於沙箱程式教育學院平台購買產品/服務時，收集您的地理位置信息、
                        收貨人姓名及聯繫電話、收貨地址、支付賬戶等信息，以匹配線下門店、實現產品/服務交付及交易的完成；
                        <span className='underline'>
                            兒童用戶於沙箱程式教育學院平台購買產品/服務的，應確保已取得監護人的授權同意。
                        </span>
                    </p>
                    <p className="li-content" >
                        <strong>
                            （2）為您能順利的完成在線課程的學習，您需在課前檢測您設備的網絡、攝像頭、
                            麥克風功能是否正常，在使用這些功能時，您需在設備向我們開啟攝像頭（相機）、
                            麥克風功能的訪問權限，您可使用這些功能與課程老師在線交流、視頻互動。
                            為了給您提供完善的產品和/或專屬服務，您在使用我們的產品和/或服務時，
                            我們將對課程進行錄音錄影。在課程結束後可以回放觀看。
                            您開啟這些權限即代表您授權我們可以收集和使用這些個人信息來實現上述的功能，
                            您亦可在設備中隨時關閉相應訪問權限，您關閉權限即代表您取消了這些授權，
                            則我們將不再繼續收集和使用您的這些個人信息，也無法為您提供上述與這些授權所對應的功能，
                            如您繼續使用這些功能您需重新開啟訪問權限。您關閉權限的決定不會影響此前基於您的授權所進行的個人信息的處理。
                        </strong>
                    </p>
                    <p className="li-content" >
                        <strong>
                            （3）向您提供在線課程互動功能時，沙箱程式教育學院可能需要收集您的客戶端的頁面截屏和收集課程對應的學生作品，
                            以改善沙箱程式教育學院的產品或服務（請在課程互動中保持客戶端的最大化狀態，以確保截屏過程中不會出現您的其他個人信息）。
                        </strong>
                    </p>
                    <p className="li-content" >
                        <strong>
                            （4）當您使用沙箱程式教育學院平台app連接沙箱程式教育學院開發的機器人設備時，
                            我們會在獲得您的明示同意後，訪問您的藍芽功能。拒絶提供該信息僅會使你無法使用上述功能，
                            但不影響你正常使用app的其他功能。
                        </strong>
                    </p>
                    <p className="li-content" >
                        <strong>
                            （5）為向您提供論壇等信息發佈功能，
                            沙箱程式教育學院可能需要根據您的賬戶信息及網絡日誌信息以判斷您是否享有對應權限。
                            請注意，您公開發佈的信息中可能會涉及您或他人的個人信息甚至個人敏感信息，需要您更加謹慎地考慮；
                            <span className='underline'>
                                若您公開發佈的信息中涉及兒童個人信息的，您需在發佈前徵得對應兒童監護人的同意。
                            </span>
                        </strong>
                    </p>
                    <h6>
                        <strong>
                            3. 為改善沙箱程式教育學院的產品或服務、向您提供個性化的信息搜索及服務，沙箱程式教育學院會收集您的年齡
                            、性別、瀏覽及搜索記錄等信息，提取您的瀏覽、搜索等使用偏好、行為習慣、訪問記錄、
                            位置信息等特徵信息，以獲得全面的研究數據樣本，基於特徵標籤進行間接人群畫像並展示、
                            推送產品或服務信息。
                        </strong>
                        同時便於您查詢您的交易狀態或歷史記錄，
                        沙箱程式教育學院及沙箱程式教育學院合作的相關服務提供方會保存您申請或使用服務過程當中提交或產生的必要信息。
                    </h6>
                    <h6>
                        <strong>
                            4.根據國家相關法律政策的要求，如部分功能需要實行實名制管理的，
                            我們將會收集您的身份信息（身份證、護照等身份證件信息）、聯繫方式等信息以完成實名認證。
                            同時，為了驗證您提供信息的準確性和完整性，相關服務提供方會與合法留存您的信息的相關機關/機構/單位進行核對。
                        </strong>
                    </h6>
                    <h6>
                        <strong>
                            5.開展內部數據分析和研究，第三方SDK統計服務，改善沙箱程式教育學院的產品或服務：
                        </strong>
                    </h6>
                    <p className="margin-left" >
                        沙箱程式教育學院收集數據是根據您與沙箱程式教育學院的互動和您所做出的選擇，
                        包括您的隱私設置以及您使用的產品和功能。
                        <strong>
                            沙箱程式教育學院收集的數據可能包括SDK/API/JS代碼版本、瀏覽器、互聯網服務提供商、IP地址、
                            GPS位置以及能夠提供相關信息的WLAN接入點、平台、時間戳、應用標識符、應用程序版本、應用分發渠道、
                            獨立設備標識符、iOS廣告標識符（IDFA）、安卓廣告主標識符、網卡（MAC）地址、國際移動設備識別碼（IMEI）
                            、設備型號、語言所在地、時區和網絡狀態等。
                        </strong>
                    </p>
                    <h6>
                        <strong>
                            6.安全風險防範：
                        </strong>
                    </h6>
                    <p className="margin-left" >
                        為了提高您使用服務的安全性，防止您的個人信息被不法分子獲取，
                        <strong>
                            沙箱程式教育學院需要記錄您使用的服務類別、方式及相關操作信息，
                            例如：設備型號、IP地址、設備軟件版本信息、設備識別碼、設備加速器（如重力感應設備）
                            、設備標識符、所在位置、網絡使用習慣、交易信息以及其他與服務相關的日誌信息。
                            如您不同意提供前述信息，可能無法完成風控驗證。
                        </strong>
                    </p>
                    <h6>
                        <strong>
                            7.如您向沙箱程式教育學院提出投訴或報告事件，為保證您的賬戶及系統安全、儘快解決相關問題，
                            沙箱程式教育學院會要求您提供必要的個人信息以核實身份，並可能保存您與沙箱程式教育學院之間的通信、
                            通話等溝通記錄及相關內容（如訂單交易信息等）。
                        </strong>
                    </h6>
                    <h6>
                        <strong>
                            8.根據相關法律法規及國家標準，在以下情形中，沙箱程式教育學院可能會依法收集並使用您的個人信息而無需徵得您的同意：
                        </strong>
                    </h6>
                    <p className="li-content" >
                        <p>（1）與國家安全、國防安全直接相關的；</p>
                        <p>（2）與公共安全、公共衛生、重大公共利益直接相關的；</p>
                        <p>（3）與犯罪偵查、起訴、審判和判決執行等直接相關的；</p>
                        <p>（4）出於維護您或他人的生命、財產等重大合法權益但徵得同意有困難的；</p>
                        <p>（5）所收集的個人信息是您自行向社會公眾公開的；</p>
                        <p>（6）從合法公開披露的信息中收集個人信息，例如：新聞報導、政府信息公開等渠道；</p>
                        <p>（7）根據您的要求籤訂和履行合同所必需的；</p>
                        <p>（8）用於維護所提供的產品及/或服務的安全穩定運行所必需的，例如發現、處置產品及/或服務的故障；</p>
                        <p>（9）學術研究機構基於公共利益開展統計或學術研究所必要，且對外提供學術研究或描述的結果時，對結果中所包含的個人信息進行去標識化處理的；</p>
                        <p>（10）法律法規規定的其他情形。</p>                            
                    </p>
                    <p className="margin-left" >
                        請知悉，
                        <strong>
                            若沙箱程式教育學院對個人信息採取技術措施和其他必要措施進行處理，
                            使得數據接收方無法重新識別特定個人且不能復原，則此類處理後數據的使用無需另行向您通知並徵得您的同意。
                        </strong>
                    </p>
                    <p className="margin-left" >
                        如我們停止運營沙箱程式教育學院平台，我們將及時停止繼續收集您個人信息的活動，
                        將停止運營的通知以公告的形式通知您，並對我們所持有的與已關停業務相關的個人信息進行刪除或匿名化處理。
                        涉及兒童個人信息的，我們會並將停止運營的通知及時告知兒童監護人。
                    </p>
                    <h5>二、 沙箱程式教育學院如何使用Cookie和同類技術</h5>
                    <p className="margin-left" >
                        為使您獲得更輕鬆的訪問體驗，您訪問沙箱程式教育學院平台或使用各項服務時，
                        相關服務提供方可能會通過小型數據文件識別您的身份，這麼做可幫您省去重複輸入註冊信息的步驟，
                        或者幫助判斷您的賬戶安全狀態。這些數據文件可能是Cookie，Flash Cookie，
                        您的瀏覽器或關聯應用程序提供的其他本地存儲（以下簡稱“Cookie”）。
                        <strong>
                            請您理解，某些服務只能通過使用Cookie才可得到實現。
                            如您的瀏覽器或瀏覽器附加服務允許，您可以修改對Cookie的接受程度或者拒絶相關Cookie。
                        </strong>
                        多數瀏覽器工具條中的“幫助”部分會告訴您怎樣防止您的瀏覽器接受新的Cookie，
                        怎樣讓您的瀏覽器在您收到一條新Cookie時通知您或者怎樣徹底關閉Cookie。
                        此外，您可以通過改變瀏覽器附加程序的設置，或通過訪問提供商的網頁，
                        來關閉或刪除瀏覽器附加程序使用的類似數據（例如：Flash Cookie)。
                         <strong>
                            但這一舉動在某些情況下可能會影響您安全訪問沙箱程式教育學院平台和使用相關服務。
                        </strong>
                    </p>
                    <p className="margin-left" >
                        沙箱程式教育學院平台上還可能包含一些電子圖象（以下簡稱“單像素GIF文件”或“網絡Beacon”），
                        使用網絡Beacon可以幫助網站計算瀏覽網頁的用戶或訪問某些Cookie，
                        相關服務提供方會通過網絡Beacon收集您瀏覽網頁活動的信息，
                        例如：您訪問的頁面地址、您先前訪問的援引頁面的位置、您的瀏覽環境以及顯示設定。
                    </p>
                    <h5>三、 沙箱程式教育學院如何存儲和保護個人信息</h5>
                    <p className="margin-left" >
                        沙箱程式教育學院在中華民國境內收集和產生的個人信息將存儲在中華民國境內。
                        <span className='bold-underline'>
                            如部分產品涉及跨境業務，相關服務提供方需要向境外機構傳輸境內收集的相關個人信息的，
                            相關服務提供方會按照法律法規和相關監管部門的規定執行。
                        </span>
                        沙箱程式教育學院僅在本政策所述目的所必需期間和法律法規及監管規定的時限內保存您的個人信息。
                    </p>
                    <p className="margin-left" >
                        <span className='bold-underline'>
                            請您務必妥善保管好您的賬戶登錄名及其他身份要素。您在使用各項服務時，
                            相關服務提供方會通過您的賬戶登錄名及其他身份要素來識別您的身份。一旦您洩漏了前述信息，
                            您可能會蒙受損失，並可能產生對您不利的法律後果。
                        </span>
                        如您發現賬戶登錄名及/或其他身份要素可能或已經洩露時，請您立即和沙箱程式教育學院取得聯繫，
                        以便沙箱程式教育學院及時採取相應措施以避免或降低相關損失。
                    </p>
                    <p className="margin-left" >
                        <span className='bold-underline'>
                            【沙箱程式教育學院指定了專人負責兒童個人信息的保護，嚴格設定信息訪問權限，
                            對可能接觸到兒童個人信息的工作人員採取最小夠用授權原則，
                            並採取技術措施對工作人員處理兒童個人信息的行為進行記錄和管控，避免違法複製、
                            下載兒童個人信息。】沙箱程式教育學院還針對兒童個人信息投訴設立專門渠道，
                            可以向 support@sandboxacademy.com.tw 發送投訴、舉報信息。
                        </span>
                    </p>
                    <p className="margin-left" >
                        【沙箱程式教育學院會採取合理可行的措施，儘力避免收集無關的個人信息。
                        沙箱程式教育學院只會在達成本政策所述目的所需的期限內保留您的個人信息，
                        除非法律有強制的存留要求。在您的個人信息超出保留期間後，
                        沙箱程式教育學院會根據適用法律的要求刪除您的個人信息，或使其匿名化處理。】
                    </p>
                     <p className="margin-left" >
                        如發生個人信息安全事件後，沙箱程式教育學院將按照法律法規的要求向您告知：
                        安全事件的基本情況和可能的影響、沙箱程式教育學院已採取或將要採取的處置措施、
                        您可自主防範和降低風險的建議、對您的補救措施等。事件相關情況我們將以郵件、信函
                        、電話、推送通知等方式告知您，難以逐一告知個人信息主體時，沙箱程式教育學院會採取合理、有效的方式發佈公告。
                    </p>
                    <p className="margin-left" >
                        <span className='bold-underline'>
                            【沙箱程式教育學院已制定兒童個人信息安全事件應急預案，
                            定期組織內部相關人員進行應急響應培訓和應急演練，
                            使其掌握崗位職責和應急處置策略和規程。
                            在發生兒童個人信息安全事件後，沙箱程式教育學院將按照法律法規的要求，
                            及時向兒童及其監護人告知：安全事件的基本情況和可能的影響、
                            沙箱程式教育學院已採取或將要採取的處置措施、
                            兒童及其監護人可自主防範和降低風險的建議、對兒童及其監護人的補救措施等。
                            沙箱程式教育學院將及時將事件相關情況以平台推送通知或公告、
                            發送郵件/短消息等方式告知兒童及其監護人。難以逐一告知時，
                            沙箱程式教育學院會採取合理、有效的方式發佈相關警示信息。
                            同時，沙箱程式教育學院還將按照監管部門要求，主動上報兒童個人信息安全事件的處置情況。
                            若兒童及其監護人的合法權益受損，我們將依法承擔相應的法律責任。】
                        </span>
                    </p>
                    <h5>四、 沙箱程式教育學院如何使用個人信息</h5>
                    <p className="margin-left" >
                        <strong>
                            為了遵守國家法律法規及監管要求，以及向您提供服務及提升服務質量，
                            或保障您的賬戶安全，沙箱程式教育學院會在以下情形中使用您的信息：
                        </strong>
                    </p>
                    <p className="li-content" >
                        <p>（1）實現本政策中“沙箱程式教育學院如何收集個人信息”所述目的；</p>
                        <p>（2）為了使您知曉使用服務的狀態，沙箱程式教育學院會向您發送服務提醒；</p>
                        <p>（3）為了保障服務的穩定性與安全性，沙箱程式教育學院會將您的信息用於身份驗證、安全防範、詐騙監測、預防或禁止非法活動、降低風險、存檔和備份用途；</p>
                        <p>（4）根據法律法規或監管要求向相關部門進行報告；</p>
                        <p>（5）邀請您參與各項產品或服務有關的客戶調研；</p>
                        <p>
                            <strong>
                                （6）對您的信息進行綜合統計、分析加工，以便為您提供更加準確、個性、
                                流暢及便捷的服務，或幫助評估、改善或設計產品、服務及運營活動等。
                                沙箱程式教育學院可能根據前述信息向您提供營銷活動通知、
                                商業性電子信息或您可能感興趣的廣告，如您不希望接收此類信息，
                                您可按照沙箱程式教育學院提示的方法選擇退訂。
                            </strong>
                        </p>
                        <p>（7）本政策規定的其他用途</p>               
                    </p>
                    <p className="margin-left" >
                        <strong>
                            若我們將信息用於本政策未載明的其他用途，或者將基於特定目的收集而來的信息用於其他目的，均會事先獲得您的同意。
                        </strong>
                    </p>
                    <h5>五、 沙箱程式教育學院如何對外提供個人信息</h5>
                    <p>
                        <strong>
                            （1）業務共享
                        </strong>
                    </p>
                    <p className="margin-left" >
                        <strong>
                            為實現沙箱程式教育學院平台相關服務功能、提昇平台的服務質量，
                            沙箱程式教育學院可能會與第三方（包括但不限於支付服務提供商、物流公司、線下合作門店等）合作
                            ，以向用戶提供相關的沙箱程式教育學院服務，
                            此類合作可能需要包括但不限於沙箱程式教育學院用戶數據與第三方用戶數據的互通。                            
                        </strong>
                        在此情況下，用戶知曉並同意，如該第三方同意承擔與沙箱程式教育學院同等甚至更嚴格的保護用戶隱私的責任，
                        則沙箱程式教育學院有權將出於履行相關合作事項所需的用戶個人信息提供給該第三方。
                        沙箱程式教育學院應盡一切合理努力保護用戶個人信息的安全性。
                        <span className='bold-underline'>
                            【對於涉及兒童個人信息的，我們不允許合作夥伴進行轉委託。】
                        </span>
                    </p>
                    <p>
                        <strong>
                            （2）關聯方共享
                        </strong>
                    </p>
                    <p className="margin-left" >
                        <strong>
                            為便於向您提供沙箱程式教育學院平台服務，推薦您可能感興趣的信息，
                            識別會員賬號異常，保護沙箱程式教育學院關聯方或其他用戶或公眾的人身財產安全免遭侵害，
                            您的個人信息可能會與我們的關聯方和/或其指定的服務提供商共享。                    
                        </strong>
                        我們只會共享必要的個人信息，且受本隱私政策中所聲明目的的約束，
                        如果我們共享您的個人敏感信息或關聯公司改變個人信息的使用及處理目的，將再次徵求您的授權同意。
                        <span className='bold-underline'>
                            【對於涉及兒童個人信息的，我們不允許關聯方進行轉委託。】
                        </span>
                    </p>
                    <p>
                       （3）投訴處理
                    </p>
                    <p className="margin-left" >
                        當您投訴他人或被他人投訴時，為了保護您及他人的合法權益，
                        相關服務提供方可能會將您的姓名及有效證件號碼、聯繫方式、
                        投訴相關內容提供給消費者權益保護部門及監管機關，以便及時解決投訴糾紛，
                        但法律法規明確禁止提供的除外。
                    </p>
                    <p>
                        （4）公開披露
                    </p>
                    <p className="margin-left" >
                        <strong>
                            除在公佈中獎活動名單時會展示中獎者手機號或賬戶登錄名         
                        </strong>
                       外，原則上沙箱程式教育學院不會將您的信息進行公開披露。如確需公開披露時，
                       沙箱程式教育學院會向您告知公開披露的目的、披露信息的類型及可能涉及的敏感信息，並徵得您的明確同意。
                    </p>
                    <p>
                        （5）委託處理
                    </p>
                    <p className="margin-left" >
                        <strong>
                            為了提升信息處理效率，降低信息處理成本，或提高信息處理準確性，
                            沙箱程式教育學院可能會委託有能力的關聯公司或其他專業機構代表沙箱程式教育學院來處理用戶信息                   
                        </strong>
                        ，但沙箱程式教育學院會通過書面協議、現場審計等方式要求受託公司遵守嚴格的保密義務及採取有效的保密措施，
                        禁止其將這些信息用於未經您授權的用途。在委託關係解除時，受託公司不再保存個人信息。相關服務提供方承諾對此負責。
                        <span className='bold-underline'>
                            【對於涉及兒童個人信息的，我們不允許受託方伴進行轉委託。】
                        </span>
                    </p>
                    <p>
                        （6）轉讓
                    </p>
                    <p className="margin-left" >
                        除非取得用戶明確同意，我們不會將所收集的用戶個人信息轉讓給任何公司、組織和個人，
                        <strong>
                            但沙箱程式教育學院發生合併、分立、收購等股權或資產重組或破產清算情形需要進行用戶個人信息轉移的除外，
                            該情形下沙箱程式教育學院會要求新的持有您個人信息的主體繼續受本政策的約束。             
                        </strong>
                    </p>
                    <p className="margin-left" >
                        以下情形中，沙箱程式教育學院對您的個人信息進行共享、轉讓及公開披露無需事先徵得您的授權同意：
                    </p>
                    <p className="li-content" >
                        <p>1、與國家安全、國防安全有關的；</p>
                        <p>2、與公共安全、公共衛生、重大公共利益有關的；</p>
                        <p>3、與犯罪偵查、起訴、審判和判決執行等司法或行政執法有關的；</p>
                        <p>4、出於維護您或其他個人的生命、財產等重大合法權益但又很難得到本人同意的；</p>
                        <p>5、您自行向社會公眾公開的個人信息；</p>
                        <p>6、從合法公開披露的信息中收集個人信息的，如合法的新聞報導、政府信息公開等渠道；</p>
                        <p>7、根據您的要求籤訂和履行合同所必需的。</p>
                    </p>
                    <p className="margin-left" >
                        請知悉，若我們對個人信息採取技術措施和其他必要措施進行處理，使得數據接收方無法重新識別特定個人且不能復原，則此類處理後數據的共享、轉讓、公開披露無需另行向您通知並徵得您的同意。
                    </p>
                    <h5>六、您的權利</h5>
                    <p>
                        您可以通過以下方式訪問及管理您的信息：
                    </p>
                    <p>（1）訪問、更正、補充您的信息</p>
                    <p className="margin-left" >
                        如果您希望訪問或編輯您的賬戶中的個人基本資料信息，在登錄網頁或應用程序，
                        進入“賬戶設置-個人資料”查看和修改您的資料信息，通過“賬戶設置-賬號信息”可以修改您的密碼及更正綁定手機號等操作
                    </p>
                    <p>（2）刪除您的信息</p>
                    <p className="margin-left" >
                        您可通過“（1）訪問、更正、補充您的信息”中列明方式刪除您的部分信息。
                    </p>
                    <p className="margin-left" >
                        在以下情形中，您可以向我們提出刪除您的個人信息的請求：
                    </p>
                    <p className="li-content" >
                        <p>a)如果我們處理個人信息的行為違反法律法規；</p>
                        <p>b)如果我們收集、使用您的個人信息超出本政策規定範圍，卻未徵得您的同意；</p>
                        <p>c)如果我們處理個人信息的行為嚴重違反了與您的約定；</p>
                        <p>d)如果您不再使用我們的產品或服務，或您註銷了沙箱程式教育學院平台賬號；</p>
                        <p>e)如果我們不再為您提供產品或服務。</p>
                    </p>
                    <p className="margin-left" >
                        若我們決定響應您的刪除請求，我們還將同時通知從我們獲得您的個人信息的實體，要求其及時刪除，
                        除非法律法規另有規定，或這些實體獲得您的獨立授權。
                    </p>
                    <p className="margin-left" >
                        <strong>
                            當您從我們的服務中刪除相關信息後，因為適用的法律及安全技術，我們可能不會立即備份系統中刪除相應的信息
                            ，我們將安全地存儲您的個人信息並將其與任何進一步處理隔離，直到備份可以清除或實現匿名。
                        </strong>
                    </p>
                    <p>（3）改變您授權同意的範圍</p>
                    <p className="margin-left" >
                        每個業務功能需要一些基本的個人信息才能得以完成，除此之外，
                        對於額外收集的個人信息的收集和使用，您可以通過沙箱程式教育學院平台提供的自助途徑或與沙
                        箱程式教育學院平台客服聯繫或通過改變您的設備設置等方式給予或收回您的授權同意。
                    </p>
                    <p className="margin-left" >
                        當您收回同意後，我們將不再處理相應的個人信息，相關產品或服務體驗可能會受到影響。
                        <strong>
                            但您收回同意的決定，不會影響此前基於您的授權而開展的個人信息處理。
                        </strong>
                    </p>
                    <p>（4）註銷賬戶</p>
                    <p className="margin-left" >
                        您可通過於沙箱程式教育學院平台提交註銷申請或聯繫沙箱程式教育學院平台客服（400-8353800）
                        的方式申請註銷您的賬戶。在您主動註銷您的賬戶之後，
                        <strong>
                            我們將停止為您提供相關產品或服務，根據法律規定要求刪除您的個人信息或進行匿名化處理。
                            其中，沙箱程式教育學院Kids校園版因無須進行賬號註冊，故不涉及賬戶註銷。
                        </strong>
                    </p>
                    <p>（5）約束信息系統自動決策</p>
                    <p className="margin-left" >
                        在某些業務功能中，我們可能僅依據信息系統、算法等在內的非人工自動決策機製作出決定。
                        如果這些決定顯著影響您的合法權益，您有權要求我們作出解釋，我們也將提供適當的救濟方式。
                    </p>
                    <p>（6）響應您的上述請求</p>
                    <p className="margin-left" >
                        為保障安全，您可能需要提供書面請求，或以其他方式證明您的身份。我們可能會先要求您驗證自己的身份，然後再處理您的請求
                    </p>
                    <p className="margin-left" >
                        <strong>
                            對於您合理的請求，我們原則上不收取費用，但對多次重複、超出合理限度的請求，
                            我們將視情況收取一定成本費用。對於那些無端重複、需要過多技術手段
                            （例如，需要開發新系統或從根本上改變現行慣例）、給他人合法權益帶來風險或者非常不切實際
                            （例如，涉及備份磁帶上存放的信息）的請求，我們可能會予以拒絶。
                        </strong>
                    </p>
                    <p className="margin-left" >
                        在以下情形中，按照法律法規要求，我們將無法響應您的請求：
                    </p>
                    <p className="li-content" >
                        <p>a)與國家安全、國防安全直接相關的；</p>
                        <p>b)與公共安全、公共衛生、重大公共利益直接相關的；</p>
                        <p>c)與犯罪偵查、起訴、審判和執行判決等直接相關的；</p>
                        <p>d)有充分證據表明個人信息主體存在主觀惡意或濫用權利的；</p>
                        <p>e)出於維護個人信息主體或其他個人的生命、財產等重大合法權益但又很難得到本人同意的；</p>
                        <p>f)響應您的請求將導致個人信息主體或其他個人、組織的合法權益受到嚴重損害的；</p>
                        <p>g)涉及商業秘密的。</p>
                    </p>
                    <h5>七、我們如何處理未成年人的個人信息</h5>
                    <p className="margin-left" >
                        <strong>
                            如您為未成年人，我們要求您請您的父母或其他監護人仔細閲讀本隱私權政策，
                            並在徵得您的父母或其他監護人同意的前提下使用我們的服務或向我們提供信息。
                        </strong>
                    </p>
                    <p className="margin-left" >
                        <span className='bold-underline'>
                            如果我們識別出您是兒童用戶的，我們將通知您的監護人並要求您的監護人同意兒童個人信息收集使用規則。
                            對於經父母或其他監護人同意使用我們的產品或服務而收集兒童個人信息的情況，我們只會在法律法規允許、
                            父母或其他監護人明確同意或者保護兒童所必要的情況下使用、共享、轉讓或披露此信息。
                        </span>
                    </p>
                    <p className="margin-left" >
                        <span className='bold-underline'>
                            若您是兒童的父母或其他監護人，請您關注您監護的兒童是否是在取得您的授權同意之後使用我們的服務的。
                        </span>
                    </p>
                    <h5>八、本政策如何更新</h5>
                    <p className="margin-left" >
                        我們的隱私政策可能變更。
                    </p>
                    <p className="margin-left" >
                        未經您明確同意，我們不會限制您按照本政策所應享有的權利。我們會通過沙箱程式教育學院平台發佈對本政策所做的任何變更。
                    </p>
                    <p className="margin-left" >
                        對於重大變更，我們還會提供更為顯著的通知（如平台公告或彈窗提示等）。
                    </p>
                    <p className="margin-left" >
                        <strong>
                            本政策所指的重大變更包括但不限於因下列事項發生造成本政策內容發生的重大變化：
                        </strong>
                    </p>
                    <p className="li-content" >
                        <p>a)我們的服務模式發生重大變化。如處理個人信息的目的、處理的個人信息類型、個人信息的使用方式等；</p>
                        <p>b)我們在所有權結構、組織架構等方面發生重大變化。如業務調整、破產併購等引起的所有者變更等；</p>
                        <p>c)個人信息共享、轉讓或公開披露的主要對象發生變化；</p>
                        <p>d)您參與個人信息處理方面的權利及其行使方式發生重大變化；</p>
                        <p>e)我們負責處理個人信息安全的責任部門、聯絡方式及投訴渠道發生變化時；</p>
                        <p>f)個人信息安全影響評估報告表明存在高風險時。</p>
                    </p>
                    <p className="margin-left" >
                        我們還會將本政策的舊版本存檔，依您的申請提供您查閲。
                    </p>
                    <h5>九、 對第三方責任的聲明</h5>
                    <p className="margin-left" >
                        請您注意，您訪問的第三方網站經營者、通過沙箱程式教育學院接入的第三方服務
                        （例如：沙箱程式教育學院的APP中第三方提供的應用）和通過沙箱程式教育學院接收您的個人信息
                        的第三方可能有自己的隱私權保護政策；當您查看第三方創建的網頁或使用第三方開發的應用程序時，
                        這些第三方可能會放置他們自己的Cookie或像素標籤，這些Cookie或像素標籤不受沙箱程式教育學院的控制，
                        且它們的使用不受本政策的約束。
                        <strong>
                            如您發現這些第三方創建的網頁或第三方開發的應用程序存在風險時，建議您終止相關操作以保護您的合法權益。
                        </strong>
                    </p>
                    <p className="margin-left" >
                        如您對本政策的內容有任何疑問、意見或建議，您可通過撥打 0905115062 官方客服電話、
                        發送郵件至 support@sandboxacademy.com.tw 或郵寄至 台北市士林區中山北路六段438號5樓 法務部 隱私保護小組 聯繫我們。
                    </p>
                </div>
            </div>

        </>
    )
}

export default Policy;
