import React from "react";
import crumbs from "../../../images/gif/Crumbs.gif";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function HC() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="student-project1-frame">
                <div className="student-project1-img">
                    <a herf="" onClick={handleClickOpen}>
                        <img
                            src={crumbs}
                            alt="sandboxacademy_student_project_crumbs"
                        />
                    </a>
                </div>
                <div className="student-project1-text" onClick={handleClickOpen}>
                    <p className="student-project1-name">
                        <a
                            herf=""
                            onClick={handleClickOpen}
                            className="no-underline lighter-text"
                        >
                            對抗病毒的麵包屑
                        </a>
                    </p>
                    <p className="student-project1-des">
                        由學完四級的 16 歲女學生獨自繪圖、配音和寫程式
                    </p>
                </div>
            </div>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            className="game-close-button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className="game-wrapper">
                    <div className="iframe-wrapper">
                        <iframe
                            src="https://pixelpad.io/app/xbpxzkacejs/"
                            scrolling="no"
                            title="demo-game"
                        />
                    </div>
                    <p className="game-title">Crumbs</p>
                    <p className="game-name">
                        Anne Jiao
                        <span className="game-played">｜3893 PLAYS </span>
                    </p>
                    <p className="game-des">
                        You are the granddaughter of a baker in the small and
                        cozy town of Tatalli. The ongoing pandemic has left your
                        little brother in a frail state. However, after a new
                        and mysterious airborne virus comes to Tatalli, your
                        brother's condition is even worse. Your brother means
                        the world to you. It's up to you to find a cure and save
                        him.
                    </p>
                    <p className="game-des">
                        Instructions: <br />
                        W = jump
                        <br />
                        A = left
                        <br />
                        D = right
                        <br />
                        Space = shoots toast (but if you can, try not to shoot
                        anyone. They're just trying their best to survive.) R =
                        resets player Left-click = progresses story slides
                    </p>
                    <p className="game-des">
                        Theme: Crumbs represents the theme "Save the World" in
                        its story. The goal for the player is to save the world
                        from a virus either metaphorically or literally. "Saving
                        the world" will depend on the player's values and
                        choices.
                    </p>
                </div>
            </Dialog>
        </>
    );
}

export default HC;
