import React, { useEffect } from "react";
import "../../App.css";
import "./AboutUs.css";
import Grid from "@material-ui/core/Grid";
import group from "../../images/decorations/groupphoto.jpeg";
import logo from "../../images/aboutourlogo.gif";
import { Helmet } from "react-helmet";

const TITLE = "關於沙箱｜沙箱科技教育學院";

function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="aboutus-container">
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>

                <div className="title-background">
                    <br />
                    <h2>為什麼叫 Sandbox 沙箱學院 ?</h2>
                    <div className="title-background-text">
                        <p>
                            沙箱是指讓孩子們在電腦上「安全遊玩和創造的數位空間」。
                            我們團隊期許打造一個能盡情探索、發揮創意的學習環境給學子們！
                        </p>
                    </div>
                </div>
                <div className="about-2-container">
                    <img src="https://stratforddemo.files.wordpress.com/2019/08/academy-celebrate-celebration-267885-1-1.jpg" />
                    <div className="about-2">
                        <div className="about-2-text">
                            <br />
                            <br />
                            <div className="about-2-text-1">
                                <h2>領先台灣課綱和國教資源！</h2>
                            </div>
                            <div className="about-2-text-2">
                                <p className="more-line-height">
                                    創立於 2014
                                    年的沙箱科技學院，在北美擁有豐富的教材研發和教學經驗，自主研發的雲端學習平台。
                                </p>
                                <p className="more-line-height">
                                    歷經市場熱烈競爭，獲選為加拿大 BC
                                    省公立學校的必修教材。
                                </p>
                                <p className="more-line-height">
                                    在海外求學 15 年的留學生 Jason
                                    因緣際會與年紀相近的沙箱科技學院創辦人 Jamie
                                    展開合作，
                                    兩位青年分工組團隊，秉著上一代台灣創業家提著行李箱就衝的精神和行動力，把世界級的資源改良，
                                    引進台灣，秉持讓更多父母無需耗資送孩子出國，就能學習領先台灣課綱和國教資源的願景，致力培養
                                    年輕學子取得工業4.0產業鏈及全球資訊產業的門票。
                                </p>
                                <p className="more-line-height">
                                    致關切孩子未來和教育的父母：
                                </p>
                                <p className="more-line-height">
                                    您知道產業界和 108
                                    年新課綱，都呼籲國教與民間重點栽培哪些通用全球的競爭力嗎？
                                </p>
                                <p className="more-line-height">
                                    過去35年父母們努力栽培孩子聽說讀寫和英語力，未來還要再加上：
                                </p>
                                <p className="more-line-height">
                                    程式設計、邏輯思維和5C素養能力！
                                </p>
                                <p className="more-line-height">
                                    面臨 AI
                                    與機器人的時代，沙箱團隊誠摯的邀請您一起培養孩子迎接未來。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="all-stories">
                    <div className="stories-1">
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <img
                                    src={group}
                                    alt="sandboxacademy_tw_groupphoto"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <h2 className="yellow-text">
                                    實作啟蒙、產業接軌
                                </h2>
                                <p className="more-line-height">
                                    與國外年齡相近的學生從實做中交流，浸泡在模擬專業軟體領域的生態中，快樂學習及創造作品，累積自信。以超前國家課綱的學習，將實戰作品與國際產業接軌。
                                </p>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="stories-2 ">
                        <h2 className="blue-text centered-text margin-tb-medium">
                            看得到作品成果的程式學習歷程
                        </h2>
                        <p className="more-line-height">
                            沙箱科技學院為孩子系統性的建立邏輯思考與提供程式語言教育。
                        </p>
                        <p className="more-line-height">
                            我們致力成為STEAM教育的領航者。讓學子從團體機器人課程學解決問題、溝通協調、團隊合作到使用業界廣用的Python語言獨立思考
                            、 創新撰寫遊戲程式。
                            沙箱課程結合實體操作與雲端互動數位教材讓學生邊玩邊學。所學內容最終符合國家大學檢定和產業需求。孩子奠定的5C素養和能力將引首而望！
                        </p>
                        <p className="more-line-height">
                            邀請您與我們一起陪伴孩子成長，體驗樂在學習的課程！
                        </p>
                    </div>
                    <div className="stories-3">
                        <h2>加拿大創辦人的話：</h2>
                        <div className="stories-3-text">
                            <p className="more-line-height">
                                “Focus on student motivations, and supporting
                                that as much as possible, while challenging our
                                students to innovate, think critically, and
                                create with technologies on the fringe of our
                                hi-tech culture using the same tools used by
                                professionals in the field.”
                            </p>
                            <p> — JAMIE</p>
                            <p className="more-line-height">
                                我們專注於塑造學童的動機，挑戰學生自我創新及獨立思考。
                                <br />
                                運用產業中專業人士所使用的工具來為高科技文化帶來新元素。
                            </p>
                        </div>
                    </div>
                    <div className="stories-4">
                        <img src={logo} alt="sandboxacademy_about_our_logo" />
                    </div>
                    <div className="stories-5">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <h2 className="centerd-text">品牌大事紀</h2>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        xs={3}
                                        sm={2}
                                        md={2}
                                        className="legend-time"
                                    >
                                        <p>2015｜</p>
                                    </Grid>
                                    <Grid item xs={9} sm={10} md={10}>
                                        <p>於加拿大溫哥華成立</p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        xs={3}
                                        sm={2}
                                        md={2}
                                        className="legend-time"
                                    >
                                        <p>2016｜</p>
                                    </Grid>
                                    <Grid item xs={9} sm={10} md={10}>
                                        <p>拓展4個實體授課據點</p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        xs={3}
                                        sm={2}
                                        md={2}
                                        className="legend-time"
                                    >
                                        <p>2017｜</p>
                                    </Grid>
                                    <Grid item xs={9} sm={10} md={10}>
                                        <p>
                                            加拿大創辦人與台灣創辦人將教學資源引回台灣
                                        </p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        xs={3}
                                        sm={2}
                                        md={2}
                                        className="legend-time"
                                    >
                                        <p>2018｜</p>
                                    </Grid>
                                    <Grid item xs={9} sm={10} md={10}>
                                        <p>
                                            台灣團隊與何嘉仁文教組織合作，於台北、新北、基隆、桃園、新竹、台中據點開始授課服務
                                        </p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        xs={3}
                                        sm={2}
                                        md={2}
                                        className="legend-time"
                                    >
                                        <p>2019｜</p>
                                    </Grid>
                                    <Grid item xs={9} sm={10} md={10}>
                                        <p>
                                            經競爭篩選被加拿大卑詩省政府教育部採用為公立小學資訊課程教材，並為公立學校老師培訓授課知識和技能
                                        </p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        xs={3}
                                        sm={2}
                                        md={2}
                                        className="legend-time"
                                    >
                                        <p>2020｜</p>
                                    </Grid>
                                    <Grid item xs={9} sm={10} md={10}>
                                        <p>
                                            位於台北市的實驗教育機構「濯亞國際學院」採用Python課程為資訊課程
                                        </p>
                                        <p>
                                            台灣團隊研發出適合幼兒及小學年齡學習的5C邏輯機器人課程
                                        </p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        xs={3}
                                        sm={2}
                                        md={2}
                                        className="legend-time"
                                    >
                                        <p>2021｜</p>
                                    </Grid>
                                    <Grid item xs={9} sm={10} md={10}>
                                        <p>
                                            疫情期間協助經濟受影響的家庭，讓學生停課不停學，免費於線上學習
                                        </p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutUs;
