import React from "react";
import sp202 from "../../../images/home/sp2-2.png";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EF() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="singel-student-project2">
                <div className="student-project2-frame">
                    <a
                        herf=""
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickOpen}
                    >
                        <img src={sp202} alt="eco ferrorist" />
                    </a>
                </div>
                <p className="sp2-title">Eco Ferrorist</p>
                <p className="sp2-name">Mathew Fu</p>
                <p className="sp2-played">2152 PLAYS </p>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="game-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="game-wrapper">
                        <div className="iframe-wrapper-small">
                            <iframe
                                src="https://pixelpad.io/app/ibatzrlhmga/"
                                scrolling="no"
                                title="demo-game"
                            />
                        </div>
                        <p className="game-title">Eco Ferrorist</p>
                        <p className="game-name">
                            Mathew Fu
                            <span className="game-played">｜2152 PLAYS </span>
                        </p>
                        <p className="game-des">
                            Eco-Ferrorist follows the story of a gardener who
                            seeks to restore a bit of nature that has been lost
                            through pollution, by providing trees with water.
                            Armed with a small watering can, this gardener is
                            challenged to save the world by evading enemies and
                            battling climate change.
                        </p>
                        <p className="game-des">
                            The arrow keys or the WASD keys can be used to
                            control the character and space is used to water the
                            trees, to defeat smoke enemies and to move to the
                            next level.
                        </p>
                        <p className="game-des">
                            We ran into some challenges with implementing the
                            smoke enemy’s movement and with creating the turret
                            enemy. Some highlights to our game would be how
                            watering different trees gives different abilities
                            to the player. If we had more time, we would focus
                            on implementing levels to complement these abilities
                            better.
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default EF;
