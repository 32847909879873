import React,{useEffect} from 'react';
import '../../App.css';
import './Locations.css';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import cd from '../../images/schools/kl/7d.jpg';
import cdk from '../../images/schools/kl/7dk.jpg';
import dwl from '../../images/schools/kl/dwl.jpg';
import kl from '../../images/schools/kl/kl.jpg';
import klk from '../../images/schools/kl/klk.jpg';
import sm from '../../images/schools/kl/sm.jpg';
import btk from '../../images/schools/tp/btk.jpg';
import cyk from '../../images/schools/tp/cyk.jpg';
import mc from '../../images/schools/tp/mc.jpg';
import nhwd from '../../images/schools/tp/nhwd.jpg';
import nkk from '../../images/schools/tp/nkk.jpg';
import sl from '../../images/schools/tp/sl.jpg';
import sp from '../../images/schools/tp/sp.jpg';
import spk from '../../images/schools/tp/spk.jpg';
import tm from '../../images/schools/tp/tm.jpg';
import tmk from '../../images/schools/tp/tmk.jpg';
import stk from '../../images/schools/tp/stk.jpg';
import st from '../../images/schools/tp/st.jpg';
import bck from '../../images/schools/ntp/bck.jpg';
import bcygsl from '../../images/schools/ntp/bcygsl.jpg';
import ch from '../../images/schools/ntp/ch.jpg';
import clk from '../../images/schools/ntp/clk.jpg';
import csk from '../../images/schools/ntp/csk.jpg';
import dh from '../../images/schools/ntp/dh.jpg';
import fd from '../../images/schools/ntp/fd.jpg';
import hc from '../../images/schools/ntp/hc.jpg';
import hck from '../../images/schools/ntp/hck.jpg';
import hgk from '../../images/schools/ntp/hgk.jpg';
import kc from '../../images/schools/ntp/kc.jpg';
import lck from '../../images/schools/ntp/lck.jpg';
import lkwctk from '../../images/schools/ntp/lkwctk.jpg';
import lzk from '../../images/schools/ntp/lzk.jpg';
import scjj from '../../images/schools/ntp/scjj.jpg';
import sj from '../../images/schools/ntp/sj.jpg';
import slmrk from '../../images/schools/ntp/slmrk.jpg';
import ssk from '../../images/schools/ntp/ssk.jpg';
import tcchsl from '../../images/schools/ntp/tcchsl.jpg';
import ts from '../../images/schools/ntp/ts.jpg';
import tsk from '../../images/schools/ntp/tsk.jpg';
import yl from '../../images/schools/ntp/yl.jpg';
import yn from '../../images/schools/ntp/yn.jpg';
import cl from '../../images/schools/ty/cl.jpg';
import elfa from '../../images/schools/ty/elfa.jpg';
import nk from '../../images/schools/ty/nk.jpg';
import nl from '../../images/schools/ty/nl.jpg';
import ty from '../../images/schools/ty/ty.jpg';
import cb from '../../images/schools/hc/cb.jpg';
import fc from '../../images/schools/hc/fc.jpg';
import rgcb from '../../images/schools/hc/rgcb.jpg';
import sh from '../../images/schools/hc/sh.jpg';
import mt from '../../images/schools/tc/mt.jpg';
import ss from '../../images/schools/tc/ss.jpg';
import wh from '../../images/schools/tc/wh.jpg';
import { Helmet } from 'react-helmet'

const TITLE = '授課據點｜沙箱科技教育學院';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

function Locations() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className={classes.root}>
                <div className='alllocations-container'>
                    <Helmet>
                        <title>{ TITLE }</title>
                    </Helmet>
                    <h1 className='blue-text centerd-text margin-tb-small'>各縣市據點</h1>
                    <h6 className='centerd-text margin-tb-medium'>6縣市｜53+據點｜10000+ 學生&信任家長 </h6>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className={classes.heading}>基隆區</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className='centerd-text'>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={cd} alt='sandbaxacademy_七堵分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>七堵分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={cdk} alt='sandbaxacademy_七堵幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>七堵幼校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={dwl} alt='sandbaxacademy_大武崙分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>大武崙分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={kl} alt='sandbaxacademy_基隆分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>基隆分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={klk} alt='sandbaxacademy_基隆幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>基隆幼校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={sm} alt='sandbaxacademy_深美分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>深美分校</p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography className={classes.heading}>台北市</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className='centerd-text'>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={sl} alt='sandbaxacademy_士林分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>士林分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={cyk} alt='sandbaxacademy_中研分幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>中研分幼校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={nhwd} alt='sandbaxacademy_內湖文德分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>內湖文德分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={tm} alt='sandbaxacademy_天母分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>天母分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={tmk} alt='sandbaxacademy_天母幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>天母幼校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={btk} alt='sandbaxacademy_北投幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>北投幼校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={mc} alt='sandbaxacademy_民權分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>民權分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={sp} alt='sandbaxacademy_石牌分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>石牌分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={spk} alt='sandbaxacademy_石牌幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>石牌幼校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={st} alt='sandbaxacademy_社子分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>社子分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={stk} alt='sandbaxacademy_社子幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>社子幼校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={nkk} alt='sandbaxacademy_南港分幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>南港分幼校</p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Typography className={classes.heading}>新北市</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className='centerd-text'>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={scjj} alt='sandbaxacademy_三重加州陽光森林幼兒園' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>三重加州陽光森林</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={ssk} alt='sandbaxacademy_三峽幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>三峽幼校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={tcchsl} alt='sandbaxacademy_土城智慧森林' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>土城智慧森林</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={yl} alt='永樂分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>永樂分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={sj} alt='sandbaxacademy_汐止分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>汐止分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={clk} alt='sandbaxacademy_昌隆分幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>昌隆分幼校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={bck} alt='sandbaxacademy_板橋分幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>板橋分幼校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={bcygsl} alt='sandbaxacademy_板橋陽光森林' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>板橋陽光森林</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={lkwctk} alt='sandbaxacademy_林口維燦創客' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>林口維燦創客</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={kc} alt='sandbaxacademy_金城分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>金城分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={yn} alt='sandbaxacademy_雨農幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>雨農幼校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={hgk} alt='sandbaxacademy_後港分幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>後港分幼校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={ch} alt='sandbaxacademy_重新分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>重新分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={csk} alt='sandbaxacademy_泰山分幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>泰山分幼校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={ts} alt='sandbaxacademy_淡水分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>淡水分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={tsk} alt='sandbaxacademy_淡水幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>淡水幼校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={lck} alt='sandbaxacademy_連城分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>連城分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={hc} alt='sandbaxacademy_新莊分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>新莊分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={hck} alt='sandbaxacademy_新莊幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>新莊幼校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={fd} alt='sandbaxacademy_輔大分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>輔大分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={dh} alt='sandbaxacademy_德和分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>德和分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={slmrk} alt='sandbaxacademy_樹林牧人幼兒園' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>樹林牧人幼兒園</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={lzk} alt='sandbaxacademy_蘆洲幼校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>蘆洲幼校</p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography className={classes.heading}>桃園區</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className='centerd-text'>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={cl} alt='sandbaxacademy_中壢分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>中壢分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={nl} alt='sandbaxacademy_內壢分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>內壢分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={elfa} alt='sandbaxacademy_艾爾法分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>艾爾法分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={nk} alt='sandbaxacademy_南崁分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>南崁分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={ty} alt='sandbaxacademy_桃園分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>桃園分校</p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography className={classes.heading}>新竹區</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className='centerd-text'>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={sh} alt='sandbaxacademy_十興分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>十興分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={rgcb} alt='sandbaxacademy_日光城堡分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>日光城堡分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}  className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={cb} alt='sandbaxacademy_竹北分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>竹北分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={fc} alt='sandbaxacademy_風城分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>風城分校</p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography className={classes.heading}>台中區</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className='centerd-text'>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={wh} alt='sandbaxacademy_文心分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>文心分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={mt} alt='sandbaxacademy_美村分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>美村分校</p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className='locations-frame'>
                                    <div className='locations-frame-img'>
                                        <img src={ss} alt='sandbaxacademy_雙十分校' />
                                    </div>
                                    <Box
                                        component="div"
                                        m={1}
                                        className={`${classes.centerBox} ${classes.box}`}
                                    >
                                    </Box>
                                    <p className='lighter-text'>雙十分校</p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                </div>
            </div>
        </>
    )
}

export default Locations;
