import React from "react";
import sp207 from "../../../images/home/sp2-7.png";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function BN() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="singel-student-project2">
                <div className="student-project2-frame">
                    <a
                        herf=""
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickOpen}
                    >
                        <img src={sp207} alt="banishing nightmares" />{" "}
                    </a>
                </div>
                <p className="sp2-title">Banishing Nightmares</p>
                <p className="sp2-name">Elena Leung</p>
                <p className="sp2-played">61 PLAYS </p>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="game-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="game-wrapper">
                        <div className="iframe-wrapper-small">
                            <iframe
                                src="https://pixelpad.io/app/uaymzoxucqq/"
                                scrolling="no"
                                title="demo-game"
                            />
                        </div>
                        <p className="game-title">Banishing Nightmares</p>
                        <p className="game-name">
                            Elena Leung
                            <span className="game-played">｜61 PLAYS </span>
                        </p>
                        <p className="game-des">
                            This game represents the theme as the player is
                            meant to slay nightmares that are preventing people
                            from sleeping. As people can't sleep, they get more
                            and more sleep deprived which hinders their day to
                            day activities and functions and thus, by defeating
                            the nightmares, they save the world. This is a
                            strategy game in which player movement and attack
                            are chosen by cards on the bottom. Some of the
                            challenges we faced revolved around trying to create
                            the various assets in addition to the enemy AI.
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default BN;
