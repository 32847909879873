import React, { useEffect } from "react";
import "../../App.css";
import "./Policy.css";
import { Helmet } from "react-helmet";

const TITLE = "線上教學服務協議｜沙箱科技教育學院";

function Duty() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="policy-container">
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <h1>線上教學服務協議</h1>
                <p>
                    本契約係指「愛樂教育顧問有限公司」服務 (
                    以下簡稱「本網站」、「本服務」、「沙箱」、「我們」)
                    授權您透過網際網路連線，於 Pixelpad.io &
                    sandboxacademy.com.tw 網域使用本服務進行教學、
                    評量或其他相關服務；您須審閱本契約至少三日時間，同意後以本契約條款訂定雙方權利義務。
                </p>
                <div className="policy-section">
                    <h2>1. 契約立書契約人</h2>
                    <h5>
                        1-1.
                        您已透過報名表填寫聯絡人及學生資料，購買課程及個人資料皆載於學生個人資料頁。
                    </h5>
                    <h5>1-2. 愛樂教育顧問有限公司 (沙箱) :</h5>
                    <div className="li-content">
                        <p>• 負責人：郭基文</p>
                        <p>• 電話：0905115062</p>
                        <p>• 客戶服務電子郵件：support@sandboxacademy.com.tw</p>
                        <p>• 營業所地址：臺北市北投區中央北路4段30巷12號</p>
                        <p>• 統一編號：80679431</p>
                    </div>
                </div>
                <div className="policy-section">
                    <h2>2. 本服務內容</h2>
                    <p>包含下列各款：</p>
                    <h5>
                        2-1. 本服務之網站網址：Pixelpad.io &
                        sandboxacademy.com.tw。
                    </h5>
                    <h5>2-2. 本服務之適用對象：不限。</h5>
                    <h5>2-3. 本服務之教學內容：依您所購買的內容而定。</h5>
                    <h5>
                        2-4.
                        本服務之教學課程數量及贈送之教學課程數量：依您已購課程所載。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>3. 設備規格</h2>
                    <p>包含下列各款：</p>
                    <h5>
                        3-1.
                        為了維持良好的學習品質，您的電腦與設備必須具備下表所列之軟硬體規格，
                        若您的電腦與設備無法滿足下列規格，則可能會造成無法使用本服務，或影響本服務品質：
                    </h5>
                    <div className="li-content">
                        <p>
                            • 作業系統：Windows 7 以上，64位元或 Mac OS 10.13
                            (HighSierra)以上作業系統。
                        </p>
                        <p>
                            • 瀏覽器：您必須安裝 Google Chrome
                            瀏覽器，並維持在最新版本。
                        </p>
                        <p>
                            •
                            I/O：須保有完整的喇叭與麥克風功能，視訊鏡頭，以及至少一個
                            USB type A 接頭。
                        </p>
                        <p>• 網路速度：下載速度最低 10M/bps。</p>
                        <p>經使用人____________確認已經告知</p>
                    </div>
                    <h5>
                        3-2. 若 沙箱 未事先告知
                        3-1項規定，導致您無法使用本服務或影響本服務之品質者，您得主張本契約不生效力。
                    </h5>
                    <h5>
                        3-3. 您連結 沙箱
                        所指定網站系統之機房設備之接取網路，除經雙方約定由 沙箱
                        所提供者外，
                        應由您自行向合法經營之電信事業申請租用，租用該接取網路所生之權利義務，依您與該電信事業間契約約定之。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>4. 契約之成立生效</h2>
                    <h5>
                        4-1. 您經由網際網路申請本服務者，於 沙箱
                        所指定網頁上勾選「同意」選項，表示同意
                        申請本服務並同意以電子文件作為表示方式後， 沙箱
                        將立即以網站系統，回覆通知您利用再次
                        確認機制加以確認電子文件內容，經您再次確認後，本契約成立。
                    </h5>
                    <h5>
                        4-2.
                        除前項情形外，經雙方於本契約書上簽名或蓋章後，本契約成立。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>5. 法定代理人</h2>
                    <h5>
                        5-1.
                        您若為限制行為能力人者，本契約訂定時，應經您的法定代理人同意，
                        本契約始生效力；您若為無行為能力人者，應由您的法定代理人代理訂定契約。
                    </h5>
                    <h5>
                        5-2.
                        違反前項之約定者，除有民法第八十三條之情形者外，本契約不生效力，
                        沙箱 不得據以要求您的法定代理人負擔契約履行或賠償責任。
                    </h5>
                    <h5>
                        5-3.
                        若您為限制行為能力人，於訂定本契約前，應主動告知其身份，若刻意隱瞞，沙箱
                        得主張本契約不成立。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>6. 課程提供與服務期間</h2>
                    <p>
                        當課程處於開放狀態，而您登入沙箱系統後，即視為該堂課程已交付並開始使用。
                        您的個人帳號將在本服務確認您已購買所選購的課程後，逕行建置並以電子郵件方式通知。
                    </p>
                </div>
                <div className="policy-section">
                    <h2>7. 使用期間</h2>
                    <p>
                        當您購買沙箱的線上自學課程後，本服務授權您使用本服務之期間、次數或權利依下列之約定：
                    </p>
                    <h5>
                        7-1. 沙箱
                        提供之線上自學相關影片與練習題，以您開始購買後一年為限，超過一年後，沙箱
                        得在未通知您的狀況下逕行關閉。
                    </h5>
                    <h5>
                        7-2. 沙箱 所提供之一對一家教使用期限，每次使用以 60分鐘
                        為一單位，以您開始購買後 90天 為限，超過 90天後 沙箱
                        得不承擔提供此服務內容之義務。
                    </h5>
                    <h5>
                        7-3.
                        本服務提供之課程練習題與平台服務，以最後一堂課開始後一年為限，提供免費使用；
                        超過一年後，沙箱 得在未通知您的狀況下逕行關閉。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>8. 擔保授權</h2>
                    <p>
                        當您購買沙箱的線上自學課程後，本服務授權您使用本服務之期間、次數或權利依下列之約定：
                    </p>
                    <h5>
                        8-1. 沙箱
                        應確保其就本契約所授權您使用之服務內容，為合法權利人。
                    </h5>
                    <h5>
                        8-2. 沙箱
                        若有違反前項之情事，導致您無法繼續使用本服務的狀況，沙箱
                        須補償您無法使用之損失。
                    </h5>
                    <h5>
                        8-3. 未經 沙箱
                        事前之書面同意，您不得將使用權讓與他人使用。
                    </h5>
                    <h5>
                        8-4. 若有違反 8-3項之情事，導致 沙箱
                        商譽、銷售等利益受損，沙箱
                        得中終止契約並保有向您求償之權利。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>9. 授權使用費與退費規定</h2>
                    <h5>
                        9-1.
                        本服務授權使用費，根據您選購的方案類型，依報名表頁面所刊載。
                    </h5>
                    <h5>
                        9-2. 沙箱
                        得以優惠價格提供您自由決定是否加購其他商品、課程服務或額外加值服務。
                    </h5>
                    <h5>
                        9-3. 當您因個人因素須終中止契約，將依「沙箱
                        使用者條款」第二條規定辦理：
                    </h5>
                    <div className="li-content">
                        <p>
                            • 第一堂課上線，或課程開放後 48
                            小時內提出退費申請，全額退費。
                        </p>
                        <p>• 超過 48 小時後，恕不退費。</p>
                        <p>
                            •
                            若您使用團報方案，若有終止合約情事需整團辦理，恕不接受
                            <span className="red">個人單獨終止</span>。
                        </p>
                    </div>
                    <h5>
                        9-4. 訂立本契約時，沙箱
                        以「贈品」為名義，向您所為之贈與，於本契約終止或解除時，沙箱
                        不得向
                        您請求返還該贈與物，亦不得向您主張應自返還之費用金額當中，扣除該贈品之價額。沙箱
                        以贈送教學課 程數量、使用期限為內容而簽訂契約者，亦同。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>10. 費率調整</h2>
                    <p>
                        沙箱
                        所提供之課程服務授權使用費，調整時程將於網站首頁及本服務進行中公告。
                        但如新費率高於舊費率時您已按舊費率報名卻仍未繳費，在繳費期限前，仍應按調整生效日前之舊費率收費。
                    </p>
                </div>
                <div className="policy-section">
                    <h2>11. 積欠費用處理</h2>
                    <p>
                        您若逾期繳交應繳納之費用，如遲延給付之金額已達分期付款總金額五分之二者，
                        沙箱 得定
                        10日以上之催告期間請求您給付；逾期您若未完全給付，沙箱
                        得終
                        止本契約，終止提供本服務並向您請求清償積欠之費用，及依週年利率百分之五計
                        算之遲延利息，並請求您給付分期付款總金額扣除已給付金額後餘額百分之二十之
                        違約金及衍生之求償程序所需費用。
                    </p>
                </div>
                <div className="policy-section">
                    <h2>12. 授權使用原則</h2>
                    <h5>
                        12-1. 若有下列情形之一者，由您自負一切法律責任，沙箱
                        並得通知終止本契約且您不得拒絕：
                    </h5>
                    <div className="li-content">
                        <p>• 蓄意散布干擾 沙箱 網站系統正常運作之電腦程式。</p>
                        <p>
                            • 在 沙箱 網站系統、社群帳號上散布恐嚇、毀謗、
                            侵害他人隱私、色情或其他違反強制或禁止規定、公序良俗之文字、圖片或影像。
                        </p>
                    </div>
                    <h5>
                        12-2. 若您利用 沙箱
                        從事其他不法或違反本契約約定事項之行為，其情節重
                        大，且經 沙箱 通知您於 3日 改正而屆期未改正者，沙箱
                        得通知終止本契約且您不得拒絕。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>13. 您的義務</h2>
                    <h5>
                        13-1. 您使用 沙箱
                        自學課程所產生之授權使用費，有按時給付之義務，且對本契約終止前已產生之授權使用費，有繳納之義務。
                    </h5>
                    <h5>
                        13-2.
                        您對於您的使用者帳號與個人密碼有妥善保管以避免第三人知悉之義務。
                    </h5>
                    <h5>
                        13-3. 使用 沙箱
                        服務時，您有遵守本契約所約定之授權使用原則，及「沙箱
                        使用者條款」之義務。
                    </h5>
                    <h5>
                        13-4.
                        您所提供之個人聯絡資料及電子郵件等帳號有錯誤或已變更者，應儘速通知
                        沙箱
                        請求更正。如因您個人怠於通知而致其權益受損者，應由您自行負責。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>14. 帳號密碼非法使用之處理</h2>
                    <h5>
                        14-1.
                        利用您的使用者帳號與個人密碼，登入本服務網站系統之行為，推定為您的行為。
                    </h5>
                    <h5>
                        14-2.
                        若您發現您的使用者帳號或個人密碼遭第三人不法使用，應立即通知
                        沙箱， 經確認有遭第三人不法情事， 沙箱
                        將立即暫停您的帳號使用，並接受您更換其使用者帳號或個人密碼。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>15. 服務品質</h2>
                    <h5>
                        15-1.
                        沙箱應提供具有合理期待安全性之服務，並應確保其系統設備，無發生錯誤、畫面暫停、遲滯、中斷或不能進行連線之情形。
                    </h5>
                    <h5>
                        15-2. 沙箱 因可歸責於自己之事由，違反前項之約定者，應於
                        72小時內立即更正修復，並依時間延長您的教學影片有效期限。
                    </h5>
                    <h5>
                        15-3. 沙箱
                        負有維護所提供之教學內容或教材正確性與更正確認之責。經您通知或知悉內容可能有錯誤，沙箱
                        將於通知或知悉之日起算三個工作日內確認內容並給予回覆。
                    </h5>
                    <h5>
                        15-4.
                        於本契約有效期間內發生情節重大之系統異常或教學內容、教材錯誤之情事達三次以上，且
                        沙箱 未依規定修復更正，您得通知 沙箱 逕行終止本契約，且
                        沙箱 不得拒絕。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>16. 暫停服務之處置</h2>
                    <h5>
                        16-1. 沙箱
                        對於本服務相關軟硬體設備，進行營運上必要之搬遷、更換、升級、保養或維修時，得暫停本服務之全部或一部。
                    </h5>
                    <h5>
                        16-2. 沙箱
                        因前項事由而暫停本服務之全部或一部，應於暫停本服務七日前，以電子郵件或書面通知您。但因臨時性、急迫性或不可歸責於
                        沙箱 之事由者，不在此限。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>17. 免責事由</h2>
                    <h5>
                        17-1. 沙箱
                        除已盡善良管理人之注意義務外，不得因第三人入侵其電腦系統，而免除對您所應負之損害賠償責任。但不影響
                        沙箱 依據本契約第十五條之約定，對您應負之補償責任。
                    </h5>
                    <h5>
                        17-2. 除本契約或 沙箱
                        之廣告另有保證使用本服務之學習成效外，不保證您使用本服務之學習成效。
                    </h5>
                    <h5>
                        17-3.
                        因不可抗力事件，致本契約無法履行者，雙方當事人之任何一方均得通知對方終止本契約，契約終止後，沙箱
                        應與您協調更換課程、或協調返還尚未使用之本服務授權使用費金額比例。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>18. 個資保護及保密義務</h2>
                    <h5>
                        18-1. 沙箱
                        因提供本服務而知悉或持有您的學習紀錄或其他個人資料，除您請求查詢或第三人依據個人資料保護相關法令請求查詢者外，沙箱
                        負有保密義務並禁止對任何第三人揭露。
                    </h5>
                    <h5>
                        18-2. 沙箱
                        因提供本服務得蒐集、處理及利用您的學習紀錄或其他個人資料，對於您個人資料之蒐集、處理及利用，均遵守「沙箱
                        使用者條款」及「沙箱
                        隱私權政策」，不得逾越本契約目的之必要範圍，且應與蒐集之目的具有正當合理之關聯。
                    </h5>
                    <h5>
                        18-3. 除法律另有規定者外，沙箱
                        將不會把您的個人資料為本契約目的必要範圍外之利用，並不得於
                        沙箱 之關係企業之不同法人間流通。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>19. 契約變更</h2>
                    <h5>
                        19-1. 沙箱 修改本契約時，應於 30日
                        前於系統公告，同時以電子郵件或書面通知您。
                    </h5>
                    <h5>
                        19-2.
                        若您未為提出反對且繼續使用本服務者，沙箱將視為您已同意，並依契約變更後之內容繼續提供本服務。
                    </h5>
                    <h5>
                        19-3. 若您不同意 19-1項之變更，得於公告後 30日 內向 沙箱
                        主張終止契約，沙箱
                        應與您協調更換課程、或協調返還尚未使用之本服務授權使用費金額比例。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>20. 申訴權利</h2>
                    <p>
                        您對於 沙箱
                        所提供之服務，除得撥電話提出申訴外，亦得以電子郵件或書面，或至
                        沙箱之營業處所提出申訴，沙箱 應自接獲申訴之日起
                        15日內處理並回覆。
                    </p>
                </div>
                <div className="policy-section">
                    <h2>21. 損害賠償</h2>
                    <h5>
                        21-1. 您明白且擔保如 沙箱
                        及其管理階層、董事、員工、代理人及承包商因下列可歸責予您的情事而產生之各式主張、要求而產生之名譽及商業損失，您須負擔相關損害賠償與額外支出之賠償義務：
                    </h5>
                    <div className="li-content">
                        <p>• 您所發布或遞交之內容。</p>
                        <p>• 您使用本平台。</p>
                        <p>• 您違反本契約及</p>
                        <p>• 您使用本平台時侵犯第三人之任何權利。</p>
                    </div>
                    <h5>
                        21-2. 您明白且同意除21-1 項外，您須遵守「沙箱
                        使用者條款」第七條使用之規定，若衍生相關法律責任與商業損害，並擔保其賠償義務。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>22. 契約之附件</h2>
                    <h5>22-1. 有關本契約之附件均為本契約之一部分。</h5>
                    <h5>
                        22-2.
                        前項契約之內容，如有與本契約條款內容相牴觸者，應為有利於您之適用。
                    </h5>
                    <h5>
                        22-3. 本契約成立時， 沙箱
                        會將本契約留存，提供您下載留存。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>23. 其他</h2>
                    <h5>22-1. 有關本契約之附件均為本契約之一部分。</h5>
                    <h5>
                        23-1.
                        因本契約所生之一切爭議，雙方當事人約定適用中華民國法律。
                    </h5>
                    <h5>
                        23-2. 本契約條款內容如有疑義時，應為有利於您之解釋。
                    </h5>
                </div>
                <div className="policy-section">
                    <h2>24. 管轄法院</h2>
                    <p>
                        因本契約所生之一切爭議，雙方當事人同意以臺北地方法院為第一審管轄法院，但不得排除消費者保護法第四十七條或民事訴訟法第四百三十六條之九有關小額訴訟管轄法院之適用。
                    </p>
                </div>
            </div>
        </>
    );
}

export default Duty;
