import React from "react";
import t4s from "../../../images/gif/through4seasons.gif";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function HC() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="student-project1-frame">
                <div className="student-project1-img">
                    <a herf="" onClick={handleClickOpen}>
                        <img
                            src={t4s}
                            alt="sandboxacademy_student_project_through_four_seasons"
                        />
                    </a>
                </div>
                <div className="student-project1-text" onClick={handleClickOpen}>
                    <p className="student-project1-name">
                        <a
                            herf=""
                            onClick={handleClickOpen}
                            className="no-underline lighter-text"
                        >
                            四季樂迷戰
                        </a>
                    </p>
                    <p className="student-project1-des">
                        由 2 位學完四級的 17 歲女學生創作音樂解拼圖遊戲
                    </p>
                </div>
            </div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            className="game-close-button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className="game-wrapper">
                    <div className="iframe-wrapper">
                        <iframe
                            src="https://pixelpad.io/app/sclysthyjsn/"
                            scrolling="no"
                            title="demo-game"
                        />
                    </div>
                    <p className="game-title">Through Four Seasons</p>
                    <p className="game-name">
                        Grace Wu
                        <span className="game-played">｜1663 PLAYS </span>
                    </p>
                    <p className="game-des">
                        This game is about a warrior prepares in spring. summer
                        and fall to fight against a demon in winter. The
                        gameplay of this game is mainly parkour, and the main
                        character will learn how to use magic to beat the demon.
                        What's more, we made a small audio game to increase the
                        difficulty of the game. Try to solve the puzzel and
                        enjoy the music! We are looking forward to seeing you to
                        save the world!
                    </p>
                </div>
            </Dialog>
        </>
    );
}

export default HC;
