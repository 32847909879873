import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

function Footer() {
    return (
        <div className='footer-container'>
            <div className='footer-table'>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={3} md={3} >
                        <h2 className='blue-text'>實體課程</h2>
                        <Link to='/PythonGame' className='lighter-text no-underline'>Python 遊戲課程</Link>
                        <br/>
                        <Link to='/FiveCRobot' className='lighter-text no-underline'>5C 邏輯機器人</Link>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} >
                        <h2 className='blue-text'>線上課程</h2>
                        <Link to='/PythonOnline' className='lighter-text no-underline'>課程介紹</Link>
                        <br/>
                        <Link to='/studentresult' className='lighter-text no-underline'>學習成果展示</Link>
                        <br/>

                    </Grid>
                    <Grid item xs={6} sm={3} md={3} >
                    <h2 className='blue-text'>課程內容</h2>
                        <Link to='/PythonOnline' className='lighter-text no-underline'>Level1 宇宙大冒險</Link>
                        <br/>
                        <Link to='/PythonOnline' className='lighter-text no-underline'>Level2 墮落金剛</Link>
                        <br/>
                        <Link to='/PythonOnline' className='lighter-text no-underline'>Level3 氣球大冒險</Link>
                        <br/>
                        <Link to='/PythonOnline' className='lighter-text no-underline'>Level4 切水果大師</Link>
                        <br/>
                        <Link to='/PythonOnline' className='lighter-text no-underline'>Level5 我的冒險島</Link>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} >
                    <h2 className='blue-text'>關於我們</h2>
                        <Link to='/AboutUs' className='lighter-text no-underline'>關於沙箱</Link>
                        <br/>
                        <Link to='/Teachers' className='lighter-text no-underline'>師資成員</Link>
                        <br/>
                        <Link to='/Locations' className='lighter-text no-underline'>授課據點</Link>
                        
                    </Grid>
                </Grid>
            </div>
            <div className='footer-contact'>
                <Grid container spacing={4} >
                    <Grid item xs={12} sm={12} md={12} className='lighter-text no-underline bottom-text'>
                        <Link to='/' className='footer-title'>
                            沙箱科技學院
                        </Link>
                    </Grid>
                </Grid>
                <div className='footer-phone'>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} >
                            <p className='lighter-text no-underline bottom-text'>
                                <span>0905115062</span>
                            </p>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} >
                            
                             <p className='lighter-text no-underline bottom-text'>
                                <a href="https://lin.ee/WgKql8Y" className='lighter-text no-underline footer-line-button'>LINE 官方帳號</a>
                            </p>
                        </Grid>
                    </Grid>
                </div> 
                <div className='footer-policy'>
                    <Grid container spacing={1} >
                        <Grid item xs={4} sm={4} md={4} >
                            <Link to='/duty' className='policy-link'>
                                版權免責聲明
                            </Link>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <Link to='/privacypolicy' className='policy-link'>
                                用戶隱私政策
                            </Link>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <Link to='/aggrement' className='policy-link'>
                                教學服務協議
                            </Link>
                        </Grid>
                    </Grid>
                </div>  
                <p className='website-rights'> 
                    SandboxAcadamyTW@2021  
                </p>
                
            </div>
        </div>
    );
}

export default Footer;