import React, { useEffect } from "react";
import "../../App.css";
import "./Teachers.css";
import jason from "../../images/teachers/jason.jpeg";
import andy from "../../images/teachers/andy.png";
import bubu from "../../images/teachers/bubu.jpeg";
import jt from "../../images/teachers/jt.jpeg";
import lulu from "../../images/teachers/lulu.jpeg";
import chelsey from "../../images/teachers/chelsey.jpg";
import cj from "../../images/teachers/cj.jpeg";
import lmj from "../../images/teachers/lmj.jpeg";
import ht from "../../images/teachers/ht.jpeg";
import arthur from "../../images/teachers/Arthur.jpeg";
import karen from "../../images/teachers/Karen.jpeg";
import henry from "../../images/teachers/Henry.jpg";
import siens from "../../images/teachers/Siens.jpeg";
import penny from "../../images/teachers/Penny.jpeg";
import kemal from "../../images/teachers/Kemal.jpeg";
import gina from "../../images/teachers/Gina.jpeg";
import shen from "../../images/teachers/Shen.jpeg";
import annie from "../../images/teachers/Annie.jpeg";
import andy2 from "../../images/teachers/Andy2.png";
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";

const TITLE = "師資團隊介紹｜沙箱科技教育學院";

function Teachers() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="teachers-container margin-top-large">
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <div className='teachers-container-title'>
                    <h1 className="blue-text">師資團隊介紹</h1>
                    <h5 className="lighter-text">
                        Sandbox沙箱科技學院程式編碼及5C邏輯機器人課程，由資訊及教育相關背景的講師所組成。除了具有專業能力及教學熱忱，更經過內部程式教育審核，能真正了解學生需求及引導學習，成為孩子學習程式教育當中最好的陪伴者。
                    </h5>
                </div>
                <div className="teachers-1">
                    <h2 className="teacher-title ">核心成員</h2>
                    <Grid container spacing={3} className="centered-text">
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={jason} alt="sandboxacademy_jason" />
                            <h3 className="blue-text">Jason</h3>
                            <p>
                                英屬哥倫比亞大學
                                <br />
                                學前教育碩士
                            </p>
                            <p>教育起家的創辦人</p>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={andy} alt="sandboxacademy_andy" />
                            <h3 className="blue-text">Andy</h3>
                            <p>
                                政治大學
                                <br />
                                圖書資訊與檔案學碩士
                            </p>
                            <p>課程開發、資深編碼講師</p>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={bubu} alt="sandboxacademy_bubu" />
                            <h3 className="blue-text">BuBu</h3>
                            <p>
                                臺灣大學
                                <br />
                                生物產業傳播暨發展碩士
                            </p>
                            <p>品牌營運、5C機器人講師</p>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={jt} alt="sandboxacademy_jt" />
                            <h3 className="blue-text">JT</h3>
                            <p>
                                海洋大學
                                <br />
                                資訊工程碩士
                            </p>
                            <p>全端工程師、程式編碼講師</p>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={lulu} alt="sandboxacademy_lulu" />
                            <h3 className="blue-text">Lulu</h3>
                            <p>
                                淡江大學
                                <br />
                                教育科技、資訊工程學士
                            </p>
                            <p>美術編輯、5C機器人講師</p>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={chelsey} alt="sandboxacademy_chelsey" />
                            <h3 className="blue-text">Chelsey</h3>
                            <p>
                                臺灣科技大學
                                <br />
                                數位學習與教育碩士
                            </p>
                            <p>前端工程師</p>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={3}
                            md={3}
                            className="teacher-btn-containr"
                        ></Grid>
                    </Grid>
                </div>
                <div className="teachers-2">
                    <h2 className="teacher-title">程式編碼課</h2>
                    <Grid container spacing={3} className="centered-text">
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={arthur} alt="sandboxacademy_arthur" />
                            <h3 className="blue-text">Arthur</h3>
                            <p>
                                淡江大學
                                <br />
                                資訊管理碩士
                            </p>
                            <p>程式設計</p>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={cj} alt="sandboxacademy_cj" />
                            <h3 className="blue-text">Rex</h3>
                            <p>
                                元智大學
                                <br />
                                資訊傳播
                            </p>
                            <p>程式設計、幼兒教學</p>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={andy2} alt="sandboxacademy_andy" />
                            <h3 className="blue-text">Andy</h3>
                            <p>
                                臺灣科技大學
                                <br />
                                資訊工程學士
                            </p>
                            <p>程式設計</p>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={siens} alt="sandboxacademy_siens" />
                            <h3 className="blue-text">Siens</h3>
                            <p>
                                致理科技大學
                                <br />
                                商務科技管理系
                            </p>
                            <p>程式設計、邏輯概念教學</p>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={henry} alt="sandboxacademy_henry" />
                            <h3 className="blue-text">Henry</h3>
                            <p>
                                臺北市立大學
                                <br />
                                資訊科學系
                            </p>
                            <p>程式設計</p>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={penny} alt="sandboxacademy_penny" />
                            <h3 className="blue-text">Penny</h3>
                            <p>
                                師範大學
                                <br />
                                資訊工程碩士
                            </p>
                            <p>程式設計 、中低年級教學</p>
                        </Grid>
                    </Grid>
                </div>
                <div className="teachers-3">
                    <h2 className="teacher-title">5C 機器人課</h2>
                    <Grid container spacing={3} className="centered-text">
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={kemal} alt="sandboxacademy_kemal" />
                            <h3 className="blue-text">Kemal</h3>
                            <p>
                                政治大學
                                <br />
                                傳播碩士
                            </p>
                            <p>程式設計</p>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={shen} alt="sandboxacademy_shen" />
                            <h3 className="blue-text">Shen</h3>
                            <p>
                                淡江大學
                                <br />
                                外交與國際關係全英學士班
                            </p>
                            <p>幼兒教學、英文教學</p>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={lmj} alt="sandboxacademy_lmj" />
                            <h3 className="blue-text">Timo</h3>
                            <p>
                                國立臺北護理健康大學
                                <br />
                                嬰幼兒保育系
                            </p>
                            <p>幼兒教育</p>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={annie} alt="sandboxacademy_annie" />
                            <h3 className="blue-text">Annie</h3>
                            <p>
                                國立臺北教育大學
                                <br />
                                特殊教育系
                            </p>
                            <p>幼兒教學</p>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={ht} alt="sandboxacademy_ht" />
                            <h3 className="blue-text">Ariel</h3>
                            <p>
                                元智大學
                                <br />
                                資訊傳播系
                            </p>
                            <p>程式設計、幼兒教學</p>
                        </Grid>
                    </Grid>
                </div>
                <div className="teachers-4">
                    <h2 className="teacher-title">動力積木課</h2>
                    <Grid container spacing={3} className="centered-text">
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={karen} alt="sandboxacademy_karen" />
                            <h2 className="blue-text">Karen</h2>
                            <p>
                                實踐大學
                                <br />
                                食品營養與保健生技系
                            </p>
                            <p>幼兒教學</p>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <img src={gina} alt="sandboxacademy_gina" />
                            <h2 className="blue-text">Gina</h2>
                            <p>
                                淡江大學
                                <br />
                                資訊工程系
                            </p>
                            <p>程式設計</p>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default Teachers;
