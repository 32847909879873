import React, { useEffect } from "react";
import "./Home.css";
import "../../App.css";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import HeroSection from "../sections/HeroSection";
import WhySection from "../sections/WhySection";
import Student1Section from "../sections/Student1Section";
import AllCourseSection from "../sections/AllCourseSection";
import FeedbackSection from "../sections/FeedbackSection";
import homeusa from '../../images/home/usa.jpg';
import "reactjs-popup/dist/index.css";
import { Helmet } from "react-helmet";

const TITLE = "Sandbox沙箱科技學院｜孩子的程式教育起點｜兒童python課程、5C邏輯課程";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

function Home() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HeroSection />
            <div className="home-container">
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <div className="result-container">
                    <h1 className="blue-text centered-text">
                        來沙箱學北美現在最夯課程
                    </h1>
                    <p className="more-line-height">
                        讓台灣學子與美加同步，從小學習 Python
                        和邏輯思維，創作遊戲程式並參加海外曝光活動。
                    </p>
                    <p className="more-line-height">
                        沙箱的學生能夠搶先累積實務創作經歷，所學內容符合國家
                        APCS 檢定考和未來高薪產業職需。
                    </p>
                </div>

                <div className='usa-container'>
                <img src={homeusa} alt="sandboxacademy_north_america"/>
                </div>
                <WhySection />
                <Student1Section />
                <AllCourseSection />
                <FeedbackSection />
                <div className="trial-container white-text">
                    <div className="trial-text-container">
                        <h1 className="margin-tb-medium ">
                            體驗線上兒童 Python 課程
                        </h1>
                        <p className="more-line-height">
                            後疫情時代，遠距學習逐漸成為未來教學趨勢，沙箱所開設的兒童
                            Python 課程，針對 10-15
                            歲的孩子打造免出門就可學習的線上小班教學，為您對孩子提供最安全及有趣味的程式教學!
                        </p>
                        <p className="more-line-height">
                            如果您想了解更多，請填寫報名表單來獲得免費 30
                            分鐘體驗課程。我們將盡快聯繫您並安排專業師資為您的孩子線上教學。
                        </p>
                        <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLScWCCKmA7weAKLpNYjixbi8iKH0LoABwzuTvz3FG22f6r5P6w/viewform"
                            className="no-underline"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button variant="contained">體驗報名</Button>
                        </a>
                    </div>
                </div>
                <div className="add-line-container ">
                    <p>想了解程式教育與新課綱的關聯和重要性嗎？</p>
                    <p>
                        需要與專家諮詢及聯絡，歡迎加入我們的官方帳號，由專人為您安排課程與解答。
                    </p>
                    <Button
                        className="white-text trial-button"
                        onClick={handleClickOpen}
                    >
                        加入Line好友
                    </Button>
                    <Dialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                    >
                        <DialogTitle
                            id="customized-dialog-title"
                            onClose={handleClose}
                            className="centerd-text"
                        ></DialogTitle>
                        <DialogContent className="centerd-text">
                            <img
                                src="https://qr-official.line.me/sid/M/828xavtn.png"
                                alt="sandboxacademy_line_qrcode"
                            ></img>
                            <Typography gutterBottom center>
                                掃描行動條碼即可將官方帳號加入好友
                            </Typography>
                            <Typography gutterBottom center>
                                請先點選LINE應用程式搜尋欄位旁的掃描圖示，在掃描此行動條碼。
                            </Typography>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </>
    );
}

export default Home;
