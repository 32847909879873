import React from "react";
import sp209 from "../../../images/home/sp2-9.png";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AF() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="singel-student-project2">
                <div className="student-project2-frame">
                    <a
                        herf=""                    
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickOpen}
                    >
                        <img src={sp209} alt="Astronaut from the year 3000" />
                    </a>
                </div>
                <p className="sp2-title">Astronaut from the year 3000</p>
                <p className="sp2-name">darkness king</p>
                <p className="sp2-played">714 PLAYS </p>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="game-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="game-wrapper">
                        <div className="iframe-wrapper">
                            <iframe
                                src="https://pixelpad.io/app/hgveyhbvqed/"
                                scrolling="no"
                                title="demo-game"
                            />
                        </div>
                        <p className="game-title">
                            Astronaut from the year 3000
                        </p>
                        <p className="game-name">
                            darkness king
                            <span className="game-played">｜714 PLAYS </span>
                        </p>

                        <p className="game-des">
                            Bill the astronaut was sent on a mission to go back
                            in the future of the past to destroy all creatures
                            that were rivals on his present(never mistreat
                            animals and other beings in real life, this is just
                            a game).
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default AF;
