import React, {useEffect} from 'react';
import '../../App.css';
import './PythonOnline.css';
import Student2Section from '../sections/Student2Section';
import { Helmet } from 'react-helmet'

const TITLE = '學習成果展示｜沙箱科技教育學院';

function StudentResult() {

    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <Student2Section />
        </>
    )
}

export default StudentResult;
