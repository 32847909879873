import React from 'react'
import './Bot.css';
import linebot from '../../images/line.png';


function Bot() {

    return (
        <>
            <a href="https://lin.ee/WgKql8Y">
                <img src={linebot} alt='sandboxacademy_line_bot' className='line-bot' />
            </a>
        </>
    );
}

export default Bot;
