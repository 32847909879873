import React from 'react';
import '../../App.css';
import './FeedbackSection.css';
import mom from '../../images/decorations/mother.png'
import dad from '../../images/decorations/father.png'
import Carousel from 'react-bootstrap/Carousel'


function FeedbackSection() {

    return (
        <>
            <div className='feedback-container'>
                <h1 className='blue-text margin-tb-small'>
                    聽聽家長怎麼說
				</h1>
                <Carousel>
                    <Carousel.Item>
                        <div className='each-feedback-container'>
                            <img src={mom} alt="mom"/>
                            <div className='each-feedback-text'>
                                <h3>「一套完整的學習系統」</h3>
                                <p className='more-line-height'>在 SandBox 5C 邏輯機器人課程學習期間，我看見孩子不只是學到資訊科技的應用知識，更是因著系統性課程，持續啟發孩子對科技的學習和應用。</p>
                                <p>― 新北校區，劉媽媽</p>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='each-feedback-container'>
                            <img src={dad} alt="dad"/>
                            <div className='each-feedback-text'>
                                <h3>「家長看得到的學習歷程」</h3>
                                <p className='more-line-height'>老師每週即時的課程紀錄和孩子課後的開心笑容，讓我們續讀意願提升，覺得學費繳得很有價值。是非常值得推薦的課程！</p>
                                <p>― 新北校區，王爸爸</p>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='each-feedback-container'>
                            <img src={dad} alt="dad"/>
                            <div className='each-feedback-text'>
                                <h3>「可實際操作的作品成果」</h3>
                                <p className='more-line-height'>我的孩子從小三到國一都在沙箱學習程式編碼課程，寫了五個遊戲程式都可以在我的手機上玩並分享給親朋好友，孩子作品不斷累積，履歷中能展現亮眼的實力。</p>
                                <p>― 新北校區，陳爸爸</p>
                            </div>
                            
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='each-feedback-container'>
                            <img src={mom} alt="mom"/>
                            <div className='each-feedback-text'>
                                <h3>「用心陪伴與設備支援」</h3>
                                <p className='more-line-height'>上課有專用 APP 紀錄學習歷程，能看見孩子的學習狀況！課程還是北市某私立小學的正式課程，提供22吋去藍光的電腦給孩子，這樣的學費CP值爆表！</p>
                                <p>― 新北校區，王媽媽</p>
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>

            </div>
        </>
    );
}

export default FeedbackSection;







