import React from "react";
import sp2011 from "../../../images/home/sp2-11.png";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function FF() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="singel-student-project2">
                <div className="student-project2-frame">
                    <a
                        herf=""
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickOpen}
                    >
                        <img src={sp2011} alt="Fieryous Flame" />
                    </a>
                </div>
                <p className="sp2-title">Fieryous Flame</p>
                <p className="sp2-name">Nahshon Weissberg</p>
                <p className="sp2-played">467 PLAYS </p>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="game-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="game-wrapper">
                        <div className="iframe-wrapper-small">
                            <iframe
                                src="https://pixelpad.io/app/gtzbidvmumw/"
                                scrolling="no"
                                title="demo-game"
                            />
                        </div>
                        <p className="game-title">
                            Fieryous Flame – Save The World!
                        </p>
                        <p className="game-name">
                            Nahshon Weissberg
                            <span className="game-played">｜467 PLAYS </span>
                        </p>

                        <p className="game-des">
                            As the theme was "Save The World", I wanted to
                            submit a game that addressed one of many important
                            issues we face today, such as global warming, world
                            hunger, and COVID-19. The topic I chose to address
                            was the Australian forest fires. I wanted to
                            represent all those who have helped fight against
                            the raging Australia fires.
                        </p>
                        <p className="game-des">
                            Game Description: The goal of the game is simple -
                            put out all the fires you can before their countdown
                            ends. The more fires you put out, the more points
                            you gain. Game Description:
                        </p>
                        <p className="game-des">
                            The controls are as follows:
                            <br />
                            - Move Right: D or Right arrow
                            <br />
                            - Move Left: A or Left arrow
                            <br />
                            - Jump: W or Space bar
                            <br />
                            - Pickup water: Shift, S, or Down arrow
                            <br />- Extinguish Fire: Shift, S, or Down arrow{" "}
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default FF;
