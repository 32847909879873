import React, { useState } from "react";
import "./Navbars.css";
import "../../App.css";
import logo from "../../images/logo.png";
import Tooltip from "@material-ui/core/Tooltip";

function Navbars() {
    const [isToggle, setToggle] = useState("false");

    const handleToggle = () => {
        setToggle(!isToggle);
    };

    return (
        <>
            <header>
                <div className="logo">
                    <a href="/">
                        <img src={logo} alt="sandboxacademy_logo" />
                    </a>
                </div>
                <nav className={isToggle ? "nav" : "active"}>
                    <ul>
                        <li className="sub-menu">
                            <a href="/pythononline">
                                線上課程<i className="fas fa-caret-down"></i>
                            </a>
                            <ul>
                                <li>
                                    <a href="/pythononline">課程介紹</a>
                                </li>
                                <li>
                                    <a href="/studentresult">學習成果展示</a>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu">
                            <a href="/pythongame">
                                實體課程<i className="fas fa-caret-down"></i>
                            </a>
                            <ul>
                                <li>
                                    <a href="/pythongame">Python程式設計</a>
                                </li>
                                <li>
                                    <a href="/fivecrobot">5C 邏輯機器人班</a>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu">
                            <a href="/aboutus">
                                關於我們<i className="fas fa-caret-down"></i>
                            </a>
                            <ul>
                                <li>
                                    <a href="/aboutus">關於沙箱</a>
                                </li>
                                <li>
                                    <a href="/teachers">師資成員</a>
                                </li>
                                <li>
                                    <a href="/locations">授課據點</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Tooltip title="pixelpad.io" placement="bottom">
                                <a
                                    href="https://pixelpad.io/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    學習平臺
                                </a>
                            </Tooltip>
                        </li>
                    </ul>
                </nav>
                <div className="menu-toggle" onClick={handleToggle}>
                    <i className="fa fa-bars" aria-hidden="true" />
                </div>
            </header>
        </>
    );
}

export default Navbars;
