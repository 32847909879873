import React from "react";
import sp208 from "../../../images/home/sp2-8.png";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function BB() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="singel-student-project2">
                <div className="student-project2-frame">
                    <a
                        herf=""
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickOpen}
                    >
                        <img src={sp208} alt="bombbuster" />
                    </a>
                </div>
                <p className="sp2-title">Bombbuster</p>
                <p className="sp2-name">gamer 92</p>
                <p className="sp2-played">1120 PLAYS </p>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="game-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="game-wrapper">
                        <div className="iframe-wrapper">
                            <iframe
                                src="https://pixelpad.io/app/kzgoomufpie/"
                                scrolling="no"
                                title="demo-game"
                            />
                        </div>
                        <p className="game-title">
                            Bombbuster (March Break Game Jam)
                        </p>
                        <p className="game-name">
                            gamer 92
                            <span className="game-played">｜1120 PLAYS </span>
                        </p>

                        <p className="game-des">
                            Bombs are planted around the world, with the power
                            to decimate cities. You are tasked to defuse some of
                            them using the circuit boards controlling the bombs
                            before they detonate to prevent worldwide chaos.
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default BB;
