import React from "react";
import dr from "../../../images/gif/Dragon.gif";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function HC() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="student-project1-frame">
                <div className="student-project1-img">
                    <a herf="" onClick={handleClickOpen}>
                        <img
                            src={dr}
                            alt="sandboxacademy_student_project_dragon_storm"
                        />
                    </a>
                </div>
                <div className="student-project1-text" onClick={handleClickOpen}>
                    <p className="student-project1-name">
                        <a
                            herf=""
                            onClick={handleClickOpen}
                            className="no-underline lighter-text"
                        >
                            龍風暴塔防版
                        </a>
                    </p>
                    <p className="student-project1-des">
                        由 13,14,15 歲的男學生分工製作AI尋路和貨幣功能
                    </p>
                </div>
            </div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            className="game-close-button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className="game-wrapper">
                    <div className="iframe-wrapper">
                        <iframe
                            src="https://pixelpad.io/app/dwwrnwfrvqt/?fbclid=IwAR0jJtslUBAFlI5hEIzyaWY9LSrrYHPTDNw4wf6wWOG7HjweX2zjKbAstA4"
                            scrolling="no"
                            title="demo-game"
                        />
                    </div>
                    <p className="game-title">DragonStorm</p>
                    <p className="game-name">
                        Cooper Brosgall
                        <span className="game-played">｜5345 PLAYS </span>
                    </p>
                    <p className="game-des">
                        DragonStorm is a game taking place in a fantasy world
                        where you the player have to save the world. The Dragon
                        nation has attacked the Sorcerers, Elves, and Wardens
                        and you must defend and help keep the world safe. You
                        play the game by placing down units (The Elves,
                        Sorcerers, and Wardens) and upgrading them in order to
                        take down the dragons before they reach the end.
                    </p>
                    <p className="game-des">
                        This game is a far cry from the usual types of games
                        that we make, and so we had to figure out seemingly
                        simple issues such as properly making upgrades for each
                        unit, pathing for enemy units, and creating a UI in an
                        engine such as pixelPAD.
                    </p>
                    <p className="game-des">
                        A highlight of our game is that it offers lots of
                        gameplay and can be played many times with varied
                        playstyles. The code in our game is very open-ended, and
                        if we ever choose to, we have the ability to come back
                        to it and add as many levels, waves, units, and unique
                        enemies as we want. If we had more time I believe we
                        could use this strength in order to make a more in-depth
                        game with many more levels, even more unique assets, and
                        a more involved story.
                    </p>
                </div>
            </Dialog>
        </>
    );
}

export default HC;
