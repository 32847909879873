import React from "react";
import sp2013 from "../../../images/home/sp2-13.png";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TE() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="singel-student-project2">
                <div className="student-project2-frame">
                    <a
                        herf=""
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickOpen}
                    >
                        <img src={sp2013} alt="the trials of elfboy" />
                    </a>
                </div>
                <p className="sp2-title">The Trials Of Elfboy</p>
                <p className="sp2-name">Nike Gandila</p>
                <p className="sp2-played">555 PLAYS </p>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="game-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="game-wrapper">
                        <div className="iframe-wrapper">
                            <iframe
                                src="https://pixelpad.io/app/ovampymdvwc/"
                                scrolling="no"
                                title="demo-game"
                            />
                        </div>
                        <p className="game-title">The Trials Of Elfboy</p>
                        <p className="game-name">
                            Nike Gandila
                            <span className="game-played">｜555 PLAYS </span>
                        </p>

                        <p className="game-des">
                            This game represents the Theme ('Save the World')
                            because it is about how brave soul is trying to save
                            his forest home from an evil Slime threat that took
                            over his forest.
                        </p>
                        <p className="game-des">
                            Help Elfboy save his home! Battle bats and trees as
                            you challenge the Evil Slime Lord to free your home
                            from his evil clutches! Use the arrow keys to move,
                            the 'p' button to talk with the Alien, 's' to use
                            the Sword, and more!
                        </p>
                        <p className="game-des">
                            The biggest challenge was the coding itself, as I
                            have had no experience coding Python before and I
                            followed tutorials for the creation of my game.
                        </p>
                        <p className="game-des">
                            The highlights were definitely when I got my code
                            correct and the game worked, because I ran into a
                            lot of problems as a new coder, so properly
                            functioning code is always a welcome sight.
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default TE;
