import React from 'react';
import Navbars from './components/partials/Navbars';
import LineBot from './components/partials/Bot';
import Footer from './components/partials/Footer';
import Home from './components/pages/Home';
import FiveCRobot from './components/pages/FiveCRobot';
import PythonGame from './components/pages/PythonGame';
import AboutUs from './components/pages/AboutUs';
import Teachers from './components/pages/Teachers';
import Locations from './components/pages/Locations';
import PythonOnline from './components/pages/PythonOnline';
import StudentResult from './components/pages/StudentResult';
import Policy from './components/pages/Policy';
import Duty from './components/pages/Duty';
import Aggrement from './components/pages/Aggrement';
import NotFound from './components/pages/Error404';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {

  return (
    <>
        {/* <div>{data}</div>; */}
        <Router>
            <LineBot />
            <Navbars />
            <Switch>
                <Route path='/' exact component={Home} />
                <Route path='/pythononline' exact component={PythonOnline} />
                <Route path='/studentresult' exact component={StudentResult} />
                <Route path='/fivecrobot' exact component={FiveCRobot} />
                <Route path='/pythongame' exact component={PythonGame} />
                <Route path='/aboutus' exact component={AboutUs} />
                <Route path='/Teachers' exact component={Teachers} />
                <Route path='/locations' exact component={Locations} />
                <Route path='/privacypolicy' exact component={Policy} />
                <Route path='/duty' exact component={Duty} /> 
                <Route path='/aggrement' exact component={Aggrement} />                
                <Route path='*' exact component={NotFound} />
            </Switch>
            <Footer />
        </Router>
    </>
  )
}

export default App;

