import React from "react";
import sp2017 from "../../../images/home/sp2-17.png";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DS() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="singel-student-project2">
                <div className="student-project2-frame">
                    <a
                        herf=""
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickOpen}
                    >
                        <img src={sp2017} alt="DragonStorm" />
                    </a>
                </div>
                <p className="sp2-title">Dragon Storm</p>
                <p className="sp2-name">Cooper Brosgall</p>
                <p className="sp2-played">5304 PLAYS </p>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="game-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="game-wrapper">
                        <div className="iframe-wrapper-small">
                            <iframe
                                src="https://pixelpad.io/app/dwwrnwfrvqt/"
                                scrolling="no"
                                title="demo-game"
                            />
                        </div>
                        <p className="game-title">DragonStorm</p>
                        <p className="game-name">
                            Cooper Brosgall
                            <span className="game-played">｜5304 PLAYS </span>
                        </p>
                        <p className="game-des">
                            DragonStorm is a game taking place in a fantasy
                            world where you the player have to save the world.
                            The Dragon nation has attacked the Sorcerers, Elves,
                            and Wardens and you must defend and help keep the
                            world safe. You play the game by placing down units
                            (The Elves, Sorcerers, and Wardens) and upgrading
                            them in order to take down the dragons before they
                            reach the end.
                        </p>
                        <p className="game-des">
                            This game is a far cry from the usual types of games
                            that we make, and so we had to figure out seemingly
                            simple issues such as properly making upgrades for
                            each unit, pathing for enemy units, and creating a
                            UI in an engine such as pixelPAD.
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default DS;
