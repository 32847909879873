import React from "react";
import sm from "../../../images/gif/SuperMonkey.gif";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function HC() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="student-project1-frame">
                <div className="student-project1-img">
                    <a herf="" onClick={handleClickOpen}>
                        <img
                            src={sm}
                            alt="sandboxacademy_student_project_supermonkey"
                        />
                    </a>
                </div>
                <div className="student-project1-text" onClick={handleClickOpen}>
                    <p className="student-project1-name">
                        <a
                            herf=""
                            onClick={handleClickOpen}
                            className="no-underline lighter-text"
                        >
                            超級猴子世界 2D 版
                        </a>
                    </p>
                    <p className="student-project1-des">
                        由 3 位學完三級的 14 歲男學生分工進行繪圖、寫遊戲和劇本
                    </p>
                </div>
            </div>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            className="game-close-button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className="game-wrapper">
                    <div className="iframe-wrapper-small">
                        <iframe
                            src="https://pixelpad.io/app/akhingjznoa/?fbclid=IwAR3XuzMwnqyvbIFgj7KBMjnmmop0zxyf-JRW6JK_Zx3uqWQ-uAYK7thGg6Y"
                            scrolling="no"
                            title="demo-game"
                        />
                    </div>
                    <p className="game-title">Super Monkey World 2D</p>
                    <p className="game-name">
                        Isaac Santos
                        <span className="game-played">｜1552 PLAYS </span>
                    </p>
                    <p className="game-des">
                        Our game represents the theme in its storyline, which is
                        the classic "Hero Vs Villain" story. Our hero, Monkey,
                        is on a journey to thwart the plans of the Sun, which
                        has gone on a rampage and is threatening the Monkey's
                        world through pollution and destruction, building a
                        factory to disrupt the natural balance. Will Monkey be
                        able to defeat his minions and restore order?
                    </p>
                    <p className="game-des">
                        This is a platformer game, which you play with the arrow
                        keys to run around and jump. Explore the levels to
                        advance forward. Will you be able to defeat the final
                        boss?
                    </p>
                    <p className="game-des">
                        What are some challenges you came across?
                        <br />
                        One of the challenges we came across was our physics
                        engine. The gravity took a lot of fine-tuning to get
                        just right. Level building was also hard, but utilizing
                        things such as lists we managed to get everything done.
                        Of course, we came across many, many bugs in our game,
                        which we managed to patch to create this clean game.
                    </p>
                </div>
            </Dialog>
        </>
    );
}

export default HC;
