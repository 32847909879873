import React from "react";
import sp2010 from "../../../images/home/sp2-10.png";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function STP() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="singel-student-project2">
                <div className="student-project2-frame">
                    <a
                        herf=""
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickOpen}
                    >
                        <img src={sp2010} alt="Save the PongX" />
                    </a>
                </div>
                <p className="sp2-title">Save the PongX</p>
                <p className="sp2-name">Jayden Yee</p>
                <p className="sp2-played">1836 PLAYS </p>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="game-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="game-wrapper">
                        <div className="iframe-wrapper">
                            <iframe
                                src="https://pixelpad.io/app/vxevmhrssrh/"
                                scrolling="no"
                                title="demo-game"
                            />
                        </div>
                        <p className="game-title">Save the PongX</p>
                        <p className="game-name">
                            Jayden Yee
                            <span className="game-played">｜1836 PLAYS </span>
                        </p>

                        <p className="game-des">
                            This game represents the theme by having two players
                            compete to save the earth from each other (The earth
                            is the green wall, but if I had more time, I would
                            create a new game mode that changes the wall into an
                            actual earth). There is a "help" option in the main
                            menu if you would like to know how to play the game.
                        </p>
                        <p className="game-des">
                            The challenge that I ran into while coding the game
                            is that it is my first time coding in Pixelpad2d
                            Python so I had to learn along the way. The
                            highlights for my game are the implementation of
                            various game options and making the game bug-free.
                            If I had more time, I would add more game modes and
                            create a working settings option in the main menu.
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default STP;
