import React from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import './AllCourseSection.css';
import course1 from '../../images/courses/course1.jpg';
import course2 from '../../images/courses/course2.jpg';
import course3 from '../../images/courses/course3.png';
import Button from '@material-ui/core/Button';
import Box from "@material-ui/core/Box";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';


const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    box: {
        height: "5vh",
        width: "100%",
        display: "flex",
        padding: 8,
    },
    bottomRightBox: {
        justifyContent: "flex-end",
        alignItems: "flex-end"
    },
});


function AllCourseSection(props) {
    const classes = useStyles();
    const { loading = false } = props;

    return (
        <>
            <div className='allcourses-container'>
                <h1 className='blue-text margin-tb-medium centered-text'>
                    各級課程介紹
				</h1>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={6} md={4} >
                        <Card className={classes.root}>
                            <CardActionArea>
                                <div className='cardmedia-wrap'>
                                    {loading ? (
                                        <Skeleton variant="rect" animation="wave" width="100%" height="100%" style={{ backgroundColor: '#E8E9E9' }} />
                                    ) : (
                                        <CardMedia
                                            className={classes.media}
                                            image={course3}
                                            title="course 3"
                                        />
                                    )}
                                </div>
                                <CardContent>
                                    <div className='card-tag2'>
                                        <span>防疫首選</span>
                                        <span>現正熱推</span>
                                    </div>
                                    <Typography gutterBottom className='card-title-1'>
                                        線上課程
                                    </Typography>
                                    <Typography gutterBottom className='card-title-2'>
                                        三年級～國中三年級
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" className='lighter-text more-line-height'>
                                        1:4 師生比的精緻課程，學生與老師有更多的專屬時間指導互動。符合數位科技趨勢需求，疫情時代最安全的學習環境。
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Box
                                    component="span" //uses different element but default styling is same
                                    m={1}
                                    className={`${classes.bottomRightBox} ${classes.box}`}
                                >
                                    <Link to='/PythonOnline' className='no-underline courses-btn' >
                                        <Button variant="contained" color="primary" style={{ height: "3.5vh" }}>
                                            課程介紹
                                        </Button>
                                    </Link>
                                </Box>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} >
                        <Card className={classes.root}>
                            <CardActionArea>
                                <div className='cardmedia-wrap'>
                                    {loading ? (
                                        <Skeleton variant="rect" animation="wave" width="100%" height="100%" style={{ backgroundColor: '#E8E9E9' }} />
                                    ) : (
                                        <CardMedia
                                            className={classes.media}
                                            image={course1}
                                            title="course 1"
                                        />
                                    )}
                                </div>
                                <CardContent>
                                    <div className='card-tag'>
                                        <span className='yellow-tag'>程式啟蒙</span>
                                        <span className='yellow-tag'>邏輯思維</span>
                                    </div>
                                    <Typography gutterBottom className='card-title-1'>
                                        邏輯機器人課程
                                    </Typography>
                                    <Typography gutterBottom className='card-title-2'>
                                        中班～小學五年級
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" className='lighter-text more-line-height'>
                                        選用銷至歐洲的台灣之光「智高Gigo」程式教育教具，學習用精準語言來與團隊溝通協調完成任務。體驗互換角色時需要的同理心和建立檢查所需要的批判思考。奠定現在及未來職場重視的5C素養。
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Box
                                    component="span" //uses different element but default styling is same
                                    m={1}
                                    className={`${classes.bottomRightBox} ${classes.box}`}
                                >
                                    <Link to='/FiveCRobot' className='no-underline courses-btn' >
                                        <Button variant="contained" color="primary" style={{ height: "3.5vh" }}>
                                            課程介紹
                                        </Button>
                                    </Link>
                                </Box>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}  >
                        <Card className={classes.root}>
                            <CardActionArea>
                                <div className='cardmedia-wrap'>
                                    {loading ? (
                                        <Skeleton variant="rect" animation="wave" width="100%" height="100%" style={{ backgroundColor: '#E8E9E9' }} />
                                    ) : (
                                        <CardMedia
                                            className={classes.media}
                                            image={course2}
                                            title="course 2"
                                        />
                                    )}
                                </div>
                                <CardContent>
                                    <div className='card-tag'>
                                        <span>基礎養成</span>
                                        <span>程式應用</span>
                                    </div>
                                    <Typography gutterBottom className='card-title-1'>
                                        兒童 Python 課程
                                        </Typography>
                                    <Typography gutterBottom className='card-title-2'>
                                        三年級～國中三年級
                                        </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" className='lighter-text more-line-height'>
                                        在沙箱開拓的眾多合作據點中，提供專業師資與電腦設備，以遊戲啟發興趣，循序漸進引導，讓孩子就近即可學習程式設計課程。
                                        </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Box
                                    component="span" //uses different element but default styling is same
                                    m={1}
                                    className={`${classes.bottomRightBox} ${classes.box}`}
                                >
                                    <Link to='/PythonGame' className='no-underline courses-btn' >
                                        <Button variant="contained" color="primary" style={{ height: "3.5vh" }}>
                                            課程介紹
                                            </Button>
                                    </Link>
                                </Box>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}


export default AllCourseSection;




