import React from "react";
import nt from "../../../images/gif/NauticalTwilight.gif";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function HC() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="student-project1-frame">
                <div className="student-project1-img">
                    <a herf="" onClick={handleClickOpen}>
                        <img
                            src={nt}
                            alt="sandboxacademy_student_project_nauticaltwilight"
                        />
                    </a>
                </div>
                <div className="student-project1-text" onClick={handleClickOpen}>
                    <p className="student-project1-name">
                        <a
                            herf=""
                            onClick={handleClickOpen}
                            className="no-underline lighter-text"
                        >
                            深海幕光(手遊版)
                        </a>
                    </p>
                    <p className="student-project1-des">
                        由 4 位學完四級的 16 歲男學生繪製並創造自由探索環境
                    </p>
                </div>
            </div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            className="game-close-button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className="game-wrapper">
                    <div className="iframe-wrapper">
                        <iframe
                            src="https://pixelpad.io/app/lfgzohcupbm/"
                            scrolling="no"
                            title="demo-game"
                        />
                    </div>
                    <p className="game-title">Nautical Twilight</p>
                    <p className="game-name">
                        Tyler Chen
                        <span className="game-played">｜10312 PLAYS </span>
                    </p>

                    <p className="game-des">
                        In Nautical Twilight, you play as a small octopus trying
                        to save it's world; the ocean. You must retrieve three
                        gems in order to restore the water levels, and save the
                        friendly whales who help you along the way.
                    </p>
                    <p className="game-des">
                        Controls: Left click to move, and right click (or
                        space!) to dash. E to interact with NPC's and objects
                        and "F" to use echolocation once it is unlocked.
                    </p>
                </div>
            </Dialog>
        </>
    );
}

export default HC;
