import React, { useEffect } from "react";
import "../../App.css";
import "./PythonOnline.css";
import Button from "@material-ui/core/Button";
import Tabs from "../functions/TabSection";
import img01 from "../../images/pythononline/01.jpg";
import img02 from "../../images/pythononline/02.png";
import flow from "../../images/pythononline/flow.jpg";
import { Helmet } from "react-helmet";

const TITLE = "線上課程介紹｜沙箱科技教育學院";

function PythonOnline() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="python-online-container">
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <div className="python-online-img01-conatiner">
                    <img src={img01} alt="sandboxacademy_best_code_courses" />
                    <p className="img01-title-1">
                        • 好玩又實用的程式編碼課，訓練邏輯思維與創造力
                    </p>
                    <p className="img01-title-2">
                        • 小班制學習，了解遊戲程式背後的製作原理
                    </p>
                </div>
                <div className="online-demo-container">
                    <div className="python-online-img02-conatiner">
                        <img src={img02} alt="pythononline_student_teacher" />
                    </div>
                    <div className="studyathome-conatiner">
                        <h1 className="blue-text">
                            在家也能學，最適合兒童興趣啟蒙的編碼課！
                        </h1>
                        <p className="more-line-height">
                            沙箱Sandbox擁有多年兒童程式語言教學經驗，深知「引發興趣」是程式啟蒙的第一步。藉由多元且趣味的線上遊戲教程，讓孩子了解遊戲背後程式設計的流程與方法，是真正適合初學兒童，一步步建立資訊素養能力的起點。
                        </p>
                        <p className="more-line-height">
                            現在，在家就可以讓孩子線上學習編碼課程，用國際級的課程平台、專業程式教師小班制指導，隨時解答疑惑。沙箱Sandbox課程的最大特色是孩子在每一級課程結束後，皆能完成可展示的線上遊戲成為未來作品記錄。
                        </p>
                    </div>
                </div>
                <Tabs />
                <div className="flow-container">
                    <h1 className="blue-text centerd-text">線上報名流程</h1>
                    <img src={flow} alt="sandboxacademy_apply_flow" />
                </div>
                <div className="trial-container white-text">
                    <div className="trial-text-container">
                        <h1 className="margin-tb-medium ">
                            體驗線上兒童 Python 課程
                        </h1>
                        <p className="more-line-height">
                            後疫情時代，遠距學習逐漸成為未來教學趨勢，沙箱所開設的兒童
                            Python 課程，針對 10-15
                            歲的孩子打造免出門就可學習的線上小班教學，為您對孩子提供最安全及有趣味的程式教學!
                        </p>
                        <p className="more-line-height">
                            如果您想了解更多，請填寫報名表單來獲得免費 30
                            分鐘體驗課程。我們將盡快聯繫您並安排專業師資為您的孩子線上教學。
                        </p>
                        <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLScWCCKmA7weAKLpNYjixbi8iKH0LoABwzuTvz3FG22f6r5P6w/viewform"
                            className="no-underline"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button variant="contained">體驗報名</Button>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PythonOnline;
