import React, { useEffect } from "react";
import "../../App.css";
import "./FiveCRobot.css";
import robot from "../../images/physical/fivec.jpg";
import robot2 from "../../images/physical/fivec-2.jpg";
import robot3 from "../../images/physical/fivec-3.jpg";
import robot4 from "../../images/physical/fivec-4.jpg";
import robotvideo from '../../images/physical/fivec-video.gif'
import TabSection2 from "../functions/TabSection2";
import Carousel from "react-bootstrap/Carousel";
import { Helmet } from "react-helmet";

const TITLE = "5C邏輯機器人班｜沙箱科技教育學院";

function FiveCRobot() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="whole-fivecrobot-container">
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <div className="fivecrobot-photo-container">
                    <Carousel interval={5000}>
                        <Carousel.Item>
                            <img src={robot} alt="5c_robot_students" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={robot2} alt="5c_robot_students" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={robot3} alt="5c_robot_students" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={robot4} alt="5c_robot_students" />
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className="fivecrobot-container">
                    <h1 className="blue-text centerd-text">
                        一玩就上手的程式樂學課
                    </h1>
                    <p className="more-line-height">
                        沙箱與Gigo智高開發從幼兒開始即可學習的「5C邏輯機器人」課程，選用Gigo智高獨步全球、世界級頂尖的結構型積木，完美地將程式設計概念融入遊戲中，孩子無需從小盯著螢幕學習程式語言，而是在遊戲與團隊合作當中，訓練手眼協調、邏輯思考、組織溝通的軟實力，在邏輯機器人的學習過程中，融入程式語言各類的「指令」概念，創建快樂學習的自信，未來銜接國小編碼課更易快速上手。
                    </p>
                    <div className="robot-video-container">
                        <img src={robotvideo} alt='sandbox_academy_5c_robot_video'/>
                    </div>
                </div>
                <TabSection2 />
            </div>
        </>
    );
}

export default FiveCRobot;
