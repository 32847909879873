import React, {useEffect} from 'react';
import '../../App.css';
import './Policy.css';
import { Helmet } from 'react-helmet'

const TITLE = '版權與免責聲明｜沙箱科技教育學院';

function Duty() {

    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className='policy-container'>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
                <h1>版權與免責聲明</h1>
                <p>
                    本網站發布的所有作品，均為愛樂教育顧問有限公司原創或獲得正版授權刊載的作品，未經本公司授權不得轉載、
                    編輯或利用其它方式使用上述作品。已經本公司授權使用作品的，應在授權範圍內使用、並註明"來源:沙箱程式設計學院"。
                    違反上述聲明者，本公司將追究其相關法律責任。
                </p>
                <p>
                    本公司在製作上述作品時，採用了部分來源網路的內容，該部分內容並未標明出處導致原作者不詳，
                    因而本公司未能獲取相應的授權。本公司非常重視知識產權問題，尊重原作者的智力成果，如果本公
                    司的上述作品使用了您的版權內容，請您及時雨本公司聯繫，因而本公司未能獲取相應的授權。本公
                    司將立即與您協商解決版權相關事宜。
                </p>
                <p>
                    郵箱: support@sandboxacademy.com.tw
                </p>
                <p>
                    愛樂教育顧問有限公司
                </p>
                <p>
                    2020年5月
                </p>
            </div>

        </>
    )
}

export default Duty;
