import React from "react";
import sp2015 from "../../../images/home/sp2-15.png";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ZS() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="singel-student-project2">
                <div className="student-project2-frame">
                    <a
                        herf=""
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickOpen}
                    >
                        <img src={sp2015} alt="zombie stomper" />
                    </a>
                </div>
                <p className="sp2-title">Zombie Stomper</p>
                <p className="sp2-name">Michali Chester, Lynden Holland</p>
                <p className="sp2-played">551 PLAYS </p>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="game-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="game-wrapper">
                        <div className="iframe-wrapper-small">
                            <iframe
                                src="https://pixelpad.io/app/jzrsbvrfdrk/"
                                scrolling="no"
                                title="demo-game"
                            />
                        </div>
                        <p className="game-title">Zombie Stomper</p>
                        <p className="game-name">
                            Michali Chester & Lynden Holland
                            <span className="game-played">｜551 PLAYS </span>
                        </p>

                        <p className="game-des">
                            Welcome to our game! The objective is to make it to
                            the next level without getting hurt by the zombies.
                            Along with that, you have to try to save the world
                            by squishing the monsters that litter the streets.
                        </p>
                        <p className="game-des">
                            Controls:
                            <br />
                            W & A -- Walk
                            <br />
                            Space -- Jump
                            <br />
                            Land on the enemy using jump -- Damage zombie
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default ZS;
