import React from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import "./Student1Section.css";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import HC from "./homeprojects/HC";
import HM from "./homeprojects/HM";
import HN from "./homeprojects/HN";
import HF from "./homeprojects/HF";
import HD from "./homeprojects/HD";

function Student1Section() {
    return (
        <>
            <div className="student-project-container centerd-text">
                <h1 className="blue-text centered-text">學生參賽上榜作品</h1>
                <h4 className="blue-text centered-text">
                    有多少人可以在履歷表上寫下
                    <br />
                    「我15歲時寫出下載超過#次的APP?」
                </h4>
                <h4 className="centered-text margin-bottom-small">
                    以下是沙箱學生創造的成果的一小部分
                </h4>
                <Grid container spacing={3} className="centerd-text">
                    <Grid item xs={12} sm={12} md={4}>
                        <HC />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <HM />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <HN />
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="centerd-text">
                    <Hidden only="xs">
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            className="student-project1-frame"
                        ></Grid>
                    </Hidden>
                    <Grid item xs={12} sm={12} md={4}>
                        <HF/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <HD/>
                        
                    </Grid>
                    <Hidden only="xs">
                        <Grid item xs={12} sm={12} md={2}></Grid>
                    </Hidden>
                    <div className="centerd-text location-btn"></div>
                </Grid>

                <Button variant="contained" className="more-result-btn">
                    <Link to="/studentresult">點我看更多作品</Link>
                </Button>
            </div>
        </>
    );
}

export default Student1Section;
