import React from "react";
import sp2012 from "../../../images/home/sp2-12.png";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function HF() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="singel-student-project2">
                <div className="student-project2-frame">
                    <a
                        herf=""
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickOpen}
                    >
                        <img src={sp2012} alt="Hardest Flappy Bird Game Ever" />
                    </a>
                </div>
                <p className="sp2-title">Hardest Flappy Bird Game Ever</p>
                <p className="sp2-name">Nico Boffa</p>
                <p className="sp2-played">233 PLAYS </p>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="game-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="game-wrapper">
                        <div className="iframe-wrapper-small">
                            <iframe
                                src="https://pixelpad.io/app/jwlgpqgswzl/"
                                scrolling="no"
                                title="demo-game"
                            />
                        </div>
                        <p className="game-title">
                            Hardest Flappy Bird Game Ever
                        </p>
                        <p className="game-name">
                            Nico Boffa
                            <span className="game-played">｜233 PLAYS </span>
                        </p>
                        <p className="game-des">
                            This game represents the theme "Save the World",
                            because it is a creation fuelled by my ideas and
                            enthusiasm to bring "Flappy Bird" to another level.
                            To play the game, press the "space" key to rise and
                            release it to drop.
                        </p>
                        <p className="game-des">
                            At the end of each game, press the "space" key again
                            to restart. A challenge I ran into was when I
                            couldn't save any more progress after a certain date
                            even before the submission, so if this never
                            happened, I think the game would have a lot more
                            special effects and sounds to it.
                        </p>
                        <p className="game-des">
                            The highlights of my game were whenever the
                            character hit a pipe or touched the ground, there
                            would be a loud explosion sound. The game is also 5
                            times faster than the original "Flappy Bird." If I
                            had more time, I would try to fix the bugs and
                            glitches that would prevent me from saving progress
                            and adding even more features to the game.
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default HF;
