import React from "react";
import sp201 from "../../../images/home/sp2-1.png";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function S() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="singel-student-project2">
                <div className="student-project2-frame">
                    <a
                        herf=""
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickOpen}
                    >
                        <img src={sp201} alt="spank" />
                    </a>
                </div>
                <p className="sp2-title">SPARK</p>
                <p className="sp2-name">Krish Thakur</p>
                <p className="sp2-played">511 PLAYS </p>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="game-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="game-wrapper">
                        <div className="iframe-wrapper-small">
                            <iframe
                                src="https://pixelpad.io/app/rhefrauyacr/"
                                scrolling="no"
                                title="demo-game"
                            />
                        </div>
                        <p className="game-title">SPARK</p>
                        <p className="game-name">
                            Krish Thakur
                            <span className="game-played">｜511 PLAYS </span>
                        </p>

                        <p className="game-des">
                            WASD to move --- Shoot with LMB --- Melee with RMB
                            --- Interact with E
                        </p>
                        <p className="game-des">
                            This game is best played in Chrome. All sprites
                            (excluding the city level background and the garden
                            level background) were custom made with animation
                            (using Piskel).
                        </p>
                        <p className="game-des">
                            SPARK is a game about putting out fires. Equipped
                            with a fire extinguisher and water bucket, you must
                            control the flames and emerge unscathed. A variety
                            of enemies that emerge from these fires will try to
                            stop you, demanding coordination and awareness of
                            your surroundings to succeed. Staying still is a
                            sure way to go up in flames.{" "}
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default S;
