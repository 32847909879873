import React from 'react';
import './TabSection.css'
import l11 from '../../images/pythononline/level1-1.jpg';
import l12 from '../../images/pythononline/level1-2.jpg';
import l21 from '../../images/pythononline/level2-1.jpg';
import l22 from '../../images/pythononline/level2-2.jpg';
import l31 from '../../images/pythononline/level3-1.jpg';
import l32 from '../../images/pythononline/level3-2.jpg';
import l41 from '../../images/pythononline/level4-1.png';
import l42 from '../../images/pythononline/level4-2.png';
import l51 from '../../images/pythononline/level5-1.png';
import l52 from '../../images/pythononline/level5-2.png';
import l1 from '../../images/pythononline/level1.gif';
import l2 from '../../images/pythononline/level2.gif';
import l3 from '../../images/pythononline/level3.gif';
import l4 from '../../images/pythononline/level4.gif';
import l5 from '../../images/pythononline/level5.gif';
// import no from '../../images/pythononline/no.png';
import Carousel from 'react-bootstrap/Carousel';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';


function TabSection() {
    return (
        <div className='tab-container'>
            <h1 className='blue-text centerd-text '>課程大綱</h1>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className=' tab-row'>
                    <Row class='flex-column'>
                        <Nav.Link eventKey="first">Level 1 <br />宇宙<br />大冒險</Nav.Link>
                        <Nav.Link eventKey="second">Level 2 <br />墮落<br />金剛</Nav.Link>
                        <Nav.Link eventKey="third">Level 3 <br />氣球<br />大冒險</Nav.Link>
                        <Nav.Link eventKey="fourth">Level 4 <br />切水果<br />大師</Nav.Link>
                        <Nav.Link eventKey="fifth">Level 5 <br />我的<br />冒險島</Nav.Link>
                    </Row>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        <Nav variant="pills" className="flex-row">
                            <Nav.Item>
                            </Nav.Item>
                            <Nav.Item>
                            </Nav.Item>
                            <Nav.Item>
                            </Nav.Item>
                            <Nav.Item>
                            </Nav.Item>
                        </Nav>
                    </Col>

                    <Col xs={12} sm={12} md={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div  className='game-gif-1'>
                                    <img src={l1} alt="python_程式設計_level1_demo"/>
                                </div>
                                <Carousel
                                    interval={100000}
                                >
                                    <Carousel.Item>
                                        <img src={l11} alt="python_程式設計_level1-1" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={l12} alt="python_程式設計_level1-2" />
                                    </Carousel.Item>
                                </Carousel>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div  className='game-gif-2'>
                                    <img src={l2} alt="python_程式設計_level2_demo" />
                                </div>
                                <Carousel
                                    interval={100000}
                                >
                                    <Carousel.Item>
                                        <img src={l21} alt="python_程式設計_level2-1" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={l22} alt="python_程式設計_level2-2" />
                                    </Carousel.Item>
                                </Carousel>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <div  className='game-gif-3'>
                                    <img src={l3} alt="python_程式設計_level3_demo" />               
                                </div>         
                                <Carousel
                                    interval={100000}
                                >
                                    <Carousel.Item>
                                        <img src={l31} alt="python_程式設計_level3-1" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={l32} alt="python_程式設計_level3-2" />
                                    </Carousel.Item>
                                </Carousel>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                                <div  className='game-gif-4'>
                                    <img src={l4} alt="python_程式設計_level4_demo"/>
                                </div>
                                <Carousel
                                    interval={100000}
                                >
                                    <Carousel.Item>
                                        <img src={l41} alt="python_程式設計_level4-1" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={l42} alt="python_程式設計_level4-2" />
                                    </Carousel.Item>
                                </Carousel>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                                <div  className='game-gif-5'>
                                    <img src={l5} alt="python_程式設計_level5_demo"/>
                                </div>
                                <Carousel
                                    interval={100000}
                                >
                                    <Carousel.Item>
                                        <img src={l51} alt="python_程式設計_level5-1" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={l52} alt="python_程式設計_level5-2" />
                                    </Carousel.Item>
                                </Carousel>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>

    );
}

export default TabSection;


