import React from "react";
import AOS from "aos";
import "./WhySection.css";
import "../../App.css";
import "reactjs-popup/dist/index.css";
import "aos/dist/aos.css";
import img1 from "../../images/home/1.png";
import img2 from "../../images/home/2.png";
import img3 from "../../images/home/3.png";
import img4 from "../../images/home/4.png";
import img5 from "../../images/home/5.png";
import img6 from "../../images/home/6.png";

AOS.init({
    duration: 500,
});

setTimeout(function () {
    AOS.init();
}, 1000);

document.addEventListener("aos:in", ({ detail }) => {
    console.log("animated in", detail);
});

document.addEventListener("aos:out", ({ detail }) => {
    console.log("animated out", detail);
});

function WhySection() {
    return (
        <>
            <div className="result-container2">
                <h1 className="blue-text centered-text">為什麼選我們？</h1>
                <div className="flow-1">
                    <div className="item item-yellow" data-aos="fade-down">
                        <div className="each-why-title">
                            <img src={img1} alt="why-1" />
                            <h3>國際課程</h3>
                        </div>
                        <div className="each-why-content">
                            <p>
                                不出國就能同步
                                <br />
                                海外公立學校的教學資源。
                            </p>
                        </div>
                    </div>
                    <div className="item  item-blue" data-aos="fade-down">
                        <div className="each-why-title">
                            <img src={img2} alt="why-2" />
                            <h3>系統學習</h3>
                        </div>
                        <div className="each-why-content">
                            <p>
                                從 5C 邏輯機器人啟蒙到
                                <br />
                                Python 課程實戰製作可用軟體。
                            </p>
                        </div>
                    </div>
                    <div className="item  item-yellow" data-aos="fade-down">
                        <div className="each-why-title">
                            <img src={img3} alt="why-3" />
                            <h3>結合產業</h3>
                        </div>
                        <div className="each-why-content">
                            <p>
                                學習程式設計背後的理論，
                                <br />
                                學以致用的創作讓業界認同。
                            </p>
                        </div>
                    </div>
                    <div className="item item-blue" data-aos="fade-down">
                        <div className="each-why-title">
                            <img src={img4} alt="why-4" />
                            <h3>作品累積</h3>
                        </div>
                        <div className="each-why-content">
                            <p>
                                所有課程皆做出「實際可操作」的線上軟體，
                                <br />
                                成為學習歷程展示亮點！
                            </p>
                        </div>
                    </div>
                    <div className="item  item-yellow" data-aos="fade-down">
                        <div className="each-why-title">
                            <img src={img5} alt="why-5" />
                            <h3>國家檢定</h3>
                        </div>
                        <div className="each-why-content">
                            <p>
                                Python 是台灣大學程式先修檢測
                                <br />
                                「APCS檢定考」中最易上手的語言。
                            </p>
                        </div>
                    </div>
                    <div className="item item-blue" data-aos="fade-down">
                        <div className="each-why-title">
                            <img src={img6} alt="why-6" />
                            <h3>接軌國際</h3>
                        </div>
                        <div className="each-why-content">
                            <p>
                                學技能並參與國際賽事
                                <br />
                                發覺自我在國際同齡學子中的競爭優劣勢。
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className='flow-2'>
                    <Grid container >
                        <Grid item sm={4} md={4} lg={2}>
                            <div class="item item-yellow" data-aos="fade-right">
                                <div className='each-why-title'>
                                    <img src={img1} alt='why-1' />
                                    <h4>國際課程</h4>
                                </div>
                                <div className='each-why-content'>
                                    <p>不出國就能同步海外公立學校的教學資源。</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item sm={4} md={4} lg={2}>
                            <div class="item  item-blue" data-aos="fade-right" data-aos-delay="150">
                                <div className='each-why-title'>
                                    <img src={img2} alt='why-2' />
                                    <h4>系統學習</h4>
                                </div>
                                <div className='each-why-content'>
                                    <p>從 5C 邏輯機器人啟蒙到 Python 課程實戰製作可用軟體。</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item sm={4} md={4} lg={2}>
                            <div class="item  item-yellow" data-aos="fade-right" data-aos-delay="300">
                                <div className='each-why-title'>
                                    <img src={img3} alt='why-3' />
                                    <h4>結合產業</h4>
                                </div>
                                <div className='each-why-content'>
                                    <p>學習程式設計背後的理論，學以致用的創作讓業界認同。</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item sm={4} md={4} lg={2}>
                            <div class="item item-blue" data-aos="fade-right" data-aos-delay="450">
                                <div className='each-why-title'>
                                    <img src={img4} alt='why-4' />
                                    <h4>作品累積</h4>
                                </div>
                                <div className='each-why-content'>
                                    <p>所有課程皆做出「實際可操作」的線上軟體，成為學習歷程展示亮點！</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item sm={4} md={4} lg={2}>
                            <div class="item  item-yellow" data-aos="fade-right" data-aos-delay="600">
                                <div className='each-why-title'>
                                    <img src={img5} alt='why-5' />
                                    <h4>國家檢定</h4>
                                </div>
                                <div className='each-why-content'>
                                    <p>Python是台灣大學程式先修檢測「APCS檢定考」中最易上手的語言。</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item sm={4} md={4} lg={2}>
                            <div class="item item-blue" data-aos="fade-right" data-aos-delay="750">
                                <div className='each-why-title'>
                                    <img src={img6} alt='why-6' />
                                    <h4>接軌國際</h4>
                                </div>
                                <div className='each-why-content'>
                                    <p>學技能並參與國際賽事來發覺自我在國際同齡學子中的競爭優劣勢。</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    
                </div> */}
            </div>
        </>
    );
}

export default WhySection;
