import React from "react";
import sp2018 from "../../../images/home/sp2-18.png";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function C() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="singel-student-project2">
                <div className="student-project2-frame">
                    <a
                        herf=""
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClickOpen}
                    >
                        <img src={sp2018} alt="Crumbs" />
                    </a>
                </div>
                <p className="sp2-title">Crumbs</p>
                <p className="sp2-name">Anne Jiao</p>
                <p className="sp2-played">3893 PLAYS </p>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="game-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="game-wrapper">
                        <div className="iframe-wrapper-small">
                            <iframe
                                src="https://pixelpad.io/app/xbpxzkacejs/"
                                scrolling="no"
                                title="demo-game"
                            />
                        </div>
                        <p className="game-title">Crumbs</p>
                        <p className="game-name">
                            Anne Jiao
                            <span className="game-played">｜3893 PLAYS </span>
                        </p>
                        <p className="game-des">
                            You are the granddaughter of a baker in the small
                            and cozy town of Tatalli. The ongoing pandemic has
                            left your little brother in a frail state. However,
                            after a new and mysterious airborne virus comes to
                            Tatalli, your brother's condition is even worse.
                            Your brother means the world to you. It's up to you
                            to find a cure and save him. 
                        </p>
                        <p className="game-des">
                            Instructions: <br/>
                            W = jump<br/>
                            A = left<br/>
                            D = right<br/>
                            Space = shoots toast (but if you
                            can, try not to shoot anyone. They're just trying
                            their best to survive.) R = resets player Left-click
                            = progresses story slides 
                        </p>
                        <p className="game-des">
                            Theme: Crumbs represents
                            the theme "Save the World" in its story. The goal
                            for the player is to save the world from a virus
                            either metaphorically or literally. "Saving the
                            world" will depend on the player's values and
                            choices. 
                        </p>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default C;
